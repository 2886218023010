/* eslint no-console: off */

const blacklistTypes = ['api/', 'router'];

const loggerMiddleware = () => (next: any) => (action: any) => {
  if (process.env.NODE_ENV === 'development') {
    const { type, payload, meta } = action;
    if (!blacklistTypes.some(blacklistType => type.includes(blacklistType))) {
      console.groupCollapsed(type);
      console.log('Payload:', payload);
      console.log('Meta:', meta);
      console.groupEnd();
    }
  }

  return next(action);
};

export default loggerMiddleware;
