import { QtmIcon, QtmTypography } from '@qtm/react';
import Rating from 'shared/compoments/rating/Rating';

type Props = {
  value: number;
  inline?: boolean;
};

const ConstellationResultCost = ({ value, inline = false }: Props) => {
  if (inline) {
    return (
      <div className="flex justify-between items-center">
        <div className="flex">
          <QtmIcon icon="rocket_launch" size="large" />
          <QtmTypography className="pl-m">Launch cost:</QtmTypography>
        </div>
        <Rating rating={value} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex">
        <QtmIcon icon="rocket_launch" size="large" />
        <div className="ml-s">
          <QtmTypography>Launch cost:</QtmTypography>
        </div>
      </div>
      <div>
        <Rating rating={value} />
      </div>
    </div>
  );
};

export default ConstellationResultCost;
