import SceneView from '@arcgis/core/views/SceneView';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import AnimationBackwardAction from './AnimationBackwardAction';
import AnimationForwardAction from './AnimationForwardAction';
import AnimationNextAction from './AnimationNextAction';
import AnimationPlayPauseAction from './AnimationPlayPauseAction';
import AnimationPoiBtn from './AnimationPoiBtn';
import AnimationPreviousAction from './AnimationPreviousAction';
import AnimationSliderSpeedBtn from './AnimationSliderSpeedBtn';
import AnimationStopAction from './AnimationStopAction';

type Props = {
  view: SceneView;
};

const AnimationSliderActions = ({ view }: Props) => {
  const { isSm } = useTailwindBreakpoints();
  return (
    <div className="actions">
      {!isSm && (
        <div>
          <AnimationPoiBtn view={view} />
        </div>
      )}
      <div className="actions-btn">
        <AnimationStopAction />
        <AnimationPreviousAction />
        <AnimationBackwardAction />
        <AnimationPlayPauseAction />
        <AnimationForwardAction />
        <AnimationNextAction />
      </div>
      <div>
        <AnimationSliderSpeedBtn />
      </div>
    </div>
  );
};

export default AnimationSliderActions;
