import { useAppDispatch } from 'hooks/redux';
import ResultHeader from 'modules/results/ResultHeader';
import AnimationOpportunities from 'modules/results/animation/AnimationOpportunities/AnimationOpportunities';
import ResultAnimationMap from 'modules/results/animation/ResultAnimationMap';
import useGetResult from 'modules/results/result-hook';
import { useEffect } from 'react';
import { initAnimation } from 'shared/reducers/animationSlice';

const ResultAnimation = () => {
  const dispatch = useAppDispatch();

  const { file } = useGetResult();

  useEffect(() => {
    if (file) {
      dispatch(initAnimation(file));
    }
  }, [file]);

  return (
    <div>
      <ResultHeader />
      <AnimationOpportunities />
      <ResultAnimationMap />
    </div>
  );
};

export default ResultAnimation;
