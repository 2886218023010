import Scrollbars from 'react-custom-scrollbars';

import { useAppSelector } from 'hooks/redux';
import AnimationOpportunitiesList from 'modules/results/animation/AnimationOpportunities/AnimationOpportunitiesList';

import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import './AnimationOpportunities.css';

const AnimationOpportunities = () => {
  const selection = useAppSelector(({ animation }) => animation.selection);
  const { isSm } = useTailwindBreakpoints();

  if (!selection || isSm) {
    return null;
  }

  return (
    <div className="animation-opportunities p-m">
      <Scrollbars autoHide autoHeight autoHeightMax="calc(100vh - 250px)" className="scrollbar">
        <AnimationOpportunitiesList />
      </Scrollbars>
    </div>
  );
};

export default AnimationOpportunities;
