import { QtmButton, QtmIcon } from '@qtm/react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setAnimationState } from 'shared/reducers/animationSlice';

const AnimationPlayPauseAction = () => {
  const dispatch = useAppDispatch();

  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const end = useAppSelector(({ animation }) => animation.end);

  const isDisabled = animationState === 'disabled';
  const canPlay = currentDate && end && dayjs(currentDate).isSameOrBefore(end);

  const onPlayPauseClick = () => {
    if (canPlay) {
      if (animationState === 'playing') {
        dispatch(setAnimationState('pause'));
      } else if (!isDisabled && currentDate) {
        dispatch(setAnimationState('playing'));
        //dispatch(toNextDate());
      }
    }
  };

  const title = animationState === 'playing' ? 'Pause' : 'Play';

  return (
    <QtmButton size="large" onClick={onPlayPauseClick} disabled={isDisabled} title={title}>
      <QtmIcon icon={animationState === 'playing' ? 'pause' : 'play_arrow'} />
    </QtmButton>
  );
};

export default AnimationPlayPauseAction;
