import AzureSecuredApp from 'AzureSecuredApp';
import TokenSecuredApp from 'TokenSecuredApp';
import { Ion } from 'cesium';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { useGetSkyOlightToken } from 'shared/authentication/tokenHook';
import { store } from 'store';

Ion.defaultAccessToken = import.meta.env.VITE_CESIUM_TOKEN;

const App = () => {
  const token = useGetSkyOlightToken();

  if (token) {
    return (
      <ReduxStoreProvider store={store}>
        <TokenSecuredApp token={token} />
      </ReduxStoreProvider>
    );
  }

  return (
    <ReduxStoreProvider store={store}>
      <AzureSecuredApp />
    </ReduxStoreProvider>
  );
};

export default App;
