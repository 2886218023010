import ArcGisMap from '@arcgis/core/Map';
import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import SceneView from '@arcgis/core/views/SceneView';
import clsx from 'clsx';
import { useAppDispatch } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import {
  areaOfInterestslayer,
  useLoadZoiFromResult,
} from 'modules/results/animation/utils/zoi-utils';
import useGetResult from 'modules/results/result-hook';
import {
  formatTimePerfDuration,
  getPerformanceLayer,
} from 'modules/results/utils/performance-utils';
import { useEffect, useRef, useState } from 'react';
import ColorLegend from 'shared/compoments/Legend/ColorLegend';
import { LegendInfo, setLegendInfo } from 'shared/reducers/mapSlice';

type Props = {
  className?: string;
};

const RevisitTimeHeatMap = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const mapRef = useRef<HTMLDivElement>(null);
  const { isSm } = useTailwindBreakpoints();
  const { file } = useGetResult();

  const [map, setMap] = useState<ArcGisMap>();
  const [view, setView] = useState<SceneView>();

  const areas = useLoadZoiFromResult();

  useEffect(() => {
    if (mapRef.current && !map && file) {
      const aMap = new ArcGisMap({
        basemap: 'dark-gray',
        ground: 'world-elevation',
        layers: [areaOfInterestslayer],
      });
      const mapView = new SceneView({
        map: aMap,
        container: mapRef.current,
        center: [0, 0],
        zoom: 0,
        spatialReference: SpatialReference.WebMercator,
        popup: {
          dockEnabled: true,
          dockOptions: {
            buttonEnabled: false,
            position: 'top-center',
          },
          viewModel: {
            includeDefaultActions: false,
          },
        },
      });

      setMap(aMap);
      setView(mapView);
    }
    return () => {
      if (map) {
        map.destroy();
      }
    };
  }, [mapRef, file]);

  useEffect(() => {
    if (view && file && map && areas && areas.length > 0) {
      const performance = file.areaOfInterest.missions[0].Performance;
      map.removeAll();
      const layer = getPerformanceLayer(performance, 'Average_RevisitTime', true);

      layer
        .when(() => layer.queryExtent())
        .then(response => {
          const extent = response.extent;
          extent.expand(2);
          view.when(() => {
            view.goTo(extent);
          });
        });
      map.add(areaOfInterestslayer);
      map.add(layer);

      const values = performance.map(p => p.Average_RevisitTime);
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);

      const nbInterval = isSm ? 2 : 3;
      const interval = (maxValue - minValue) / nbInterval;
      const indexes = Array.from(Array(nbInterval + 1).keys());

      const legendValues = indexes
        .map(i => minValue + interval * i)
        .map(v => formatTimePerfDuration(v));

      const legendInfo: LegendInfo = {
        values: legendValues,
        name: areas[0].name,
      };
      dispatch(setLegendInfo(legendInfo));

      view?.when(() => {
        const polygon = Polygon.fromJSON(areas[0].geom);
        view.goTo(polygon);
      });
    }
  }, [view, file, map, areas]);

  return (
    <>
      <div className={clsx('result-map', className)} ref={mapRef}></div>
      <ColorLegend />
    </>
  );
};

export default RevisitTimeHeatMap;
