import { ChartModifierBase2D } from 'scichart/Charting/ChartModifiers/ChartModifierBase2D';
import { ModifierMouseArgs } from 'scichart/Charting/ChartModifiers/ModifierMouseArgs';
import { HitTestInfo } from 'scichart/Charting/Visuals/RenderableSeries/HitTest/HitTestInfo';
import { EChart2DModifierType } from 'scichart/types/ChartModifierType';
import { testIsInBounds } from 'scichart/utils/pointUtil';

export interface GlobalMousePosition {
  x: number;
  y: number;
}

interface Props {
  onHover: (info: HitTestInfo) => void;
  onOut: () => void;
}

export class DetectOnOverSeriesModifier extends ChartModifierBase2D {
  readonly type: EChart2DModifierType = EChart2DModifierType.Custom;

  private props: Props;

  private globalCoords: GlobalMousePosition = {
    x: 0,
    y: 0
  };

  constructor(props: Props) {
    super();
    this.props = props;
    window.document.addEventListener('mousemove', (e: MouseEvent) => {
      this.globalCoords = {
        x: e.pageX,
        y: e.pageY
      };
    });
  }

  override modifierMouseMove(args: ModifierMouseArgs) {
    super.modifierMouseMove(args);

    if (!this.isAttached) {
      throw new Error('Should not call DetectClicksOnChartPartsModifier.modifierMouseDown if not attached');
    }

    const mousePoint = args.mousePoint;

    // Check if the mouse was over the main chart area
    const { left, right, top, bottom } = this.parentSurface?.seriesViewRect;
    if (testIsInBounds(mousePoint.x, mousePoint.y, left, bottom, right, top)) {
      // Check if the mouse was over any series
      const hitTests = this.parentSurface?.renderableSeries.asArray().map(rSeries => {
        const hit = rSeries.hitTestProvider.hitTest(mousePoint.x, mousePoint.y);
        if (hit.isHit) {
          // replace with globalcoord
          hit.xCoord = this.globalCoords.x;
          hit.yCoord = this.globalCoords.y;
        }
        return hit;
      });
      const isHit = hitTests.find(item => item.isHit);
      if (isHit) {
        this.props.onHover(isHit);
        return;
      }
    }
    this.props.onOut();
  }
}
