import { QtmHeaderBrand, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import { useNavigate } from 'react-router-dom';
import './BrandHeader.css';

const appName = import.meta.env.VITE_APP_NAME;

const BrandHeader = () => {
  const navigate = useNavigate();
  const darkMode = useAppSelector(state => state.app.darkMode);

  const onClick = () => {
    navigate('/');
  };

  const textClass = darkMode ? 'text-white-100' : 'text-black-100';

  return (
    <QtmHeaderBrand className="brand-header" onClick={onClick}>
      <img alt="thales logo" src="/logo_dark.svg" />
      <QtmTypography classes={clsx('pl-m font-bold', textClass)}>{appName}</QtmTypography>
    </QtmHeaderBrand>
  );
};

export default BrandHeader;
