import { QtmButton, QtmIcon } from '@qtm/react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setAnimationState, setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationStopAction = () => {
  const dispatch = useAppDispatch();

  const start = useAppSelector(({ animation }) => animation.start);
  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);

  if (!start) {
    return null;
  }

  const onStopClick = () => {
    dispatch(setAnimationState('ready'));
    dispatch(setCurrentDate(start));
    //moveSatellites(start);
  };

  const disabled = animationState === 'disabled' || start === currentDate;

  return (
    <QtmButton size="large" color="primary" onClick={onStopClick} disabled={disabled}>
      <QtmIcon icon="stop" />
    </QtmButton>
  );
};

export default AnimationStopAction;
