import { Dayjs } from 'dayjs';
import { ClassicalElements, EpochUTC, KeplerPropagator } from 'pious-squid';
import { KeplerianElement } from 'shared/model/resultFile.model';
import { degreesLat, degreesLong } from 'utils/math.utils';

const getGeodeticPropagation = (ceState: ClassicalElements, to: Dayjs) => {
  const keplerProp = new KeplerPropagator(ceState);
  const epoch = EpochUTC.fromDateString(to.toISOString());
  const finalState = keplerProp.propagate(epoch);
  return finalState.toITRF().toGeodetic();
};

const satOrbitalElementToClassicalElement = (keplerianElements: KeplerianElement) => {
  const epoch = EpochUTC.fromDateString(keplerianElements.epoch);
  const semimajorAxis = keplerianElements.semimajorAxis;
  const eccentricity = keplerianElements.orbitEccentricity;
  const inclinaisonRad = keplerianElements.inclination;
  const rightAscensionOfAscendingNodeRad = keplerianElements.raan;
  const periggeRad = keplerianElements.perigee;
  const trueAnomalyRad = keplerianElements.trueAnomaly;

  return new ClassicalElements(
    epoch,
    semimajorAxis,
    eccentricity,
    inclinaisonRad,
    rightAscensionOfAscendingNodeRad,
    periggeRad,
    trueAnomalyRad,
  );
};

export const propagate = (keplerianElement: KeplerianElement, endDate: Dayjs) => {
  const classicalElement = satOrbitalElementToClassicalElement(keplerianElement);
  const geodetic = getGeodeticPropagation(classicalElement, endDate);
  const longitude = degreesLong(geodetic.longitude);
  const latitude = degreesLat(geodetic.latitude);
  const z = Math.abs(geodetic.altitude * 1000);
  return {
    longitude,
    latitude,
    z,
  };
};
