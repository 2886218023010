import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//const defaultMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
const defaultMode = true;

const initialState = {
  bearerToken: '',
  token: '',
  darkMode: defaultMode,
};

export type AppState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setBearerToken: (state, action: PayloadAction<string>) => {
      state.bearerToken = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    reset: state => {
      state.darkMode = true;
      state.token = '';
      state.bearerToken = '';
    },
  },
});

export const { setDarkMode, reset, setToken, setBearerToken } = slice.actions;

export default slice.reducer;
