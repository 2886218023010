import { AcquisitionOppMetadata } from 'modules/results/opportunities/AcquisitionOppMetadata';

import './chart.css';

const debug = false;

export const getChartPopup = ({
  metaData,
  xCoord,
  yCoord,
}: {
  metaData: AcquisitionOppMetadata;
  xCoord: number;
  yCoord: number;
}) => {
  let top = yCoord;
  let left = xCoord;

  top -= 15;

  const isOppMetaData = true;

  const satellite = metaData.satellite;
  const satModel = metaData.satModel;
  const name = satellite ? satellite.name : '';
  const modelName = satModel ? satModel.name : '';
  const localDate = metaData.localDate;

  const hour = localDate.format('HH:mm');

  let previousOppStr = '';
  if (isOppMetaData && metaData.previousOppDate) {
    if (!localDate.isSame(metaData.previousOppDate, 'day')) {
      previousOppStr += '(D-1) ';
    }
    previousOppStr += metaData.previousOppDate.format('HH:mm');
  }

  let previousSatDiff = '';
  if (isOppMetaData && metaData.previousSatDate) {
    const previousSatDate = metaData.previousSatDate;
    if (!localDate.isSame(previousSatDate, 'day')) {
      previousSatDiff += '(D-1) ';
    }
    previousSatDiff += previousSatDate.format('HH:mm');
  }

  const color = metaData.defaultColor;

  const dayFromStart = localDate.diff(metaData.startDate, 'day') + 1;

  return (
    <div
      id="chart-popup"
      className="chart-popup"
      style={{
        top,
        left,
      }}
    >
      <div>
        <div className="font-weight-bold mb-2 h6" style={{ color }}>
          {name}
        </div>
        <div className="field">
          <div>Type</div>
          <div>{modelName}</div>
        </div>
        <div className="field">
          <div>Simulation day</div>
          <div>{dayFromStart}</div>
        </div>
        <div className="field">
          <div>Opportunity</div>
          <div>{hour}</div>
        </div>
        {debug && <div>{localDate.toISOString()}</div>}
        {previousOppStr && (
          <>
            <div className="field">
              <div>Previous opportunity</div>
              <div>{previousOppStr}</div>
            </div>
            {debug && <div>{metaData.previousOppDate?.toISOString()}</div>}
          </>
        )}
        {previousSatDiff && (
          <>
            <div className="field">
              <div>Previous Satellite</div>
              <div>{previousSatDiff}</div>
            </div>
            {debug && <div>{metaData.previousSatDate?.toISOString()}</div>}
          </>
        )}
      </div>
    </div>
  );
};
