import ArcGisMap from '@arcgis/core/Map';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';

import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import SceneView from '@arcgis/core/views/SceneView';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import { useEffect, useRef, useState } from 'react';
import { setAreaGraphic, setAreaId } from 'shared/reducers/selectionSlice';

let highlighted: __esri.Handle | null = null;
let featureLayer: FeatureLayer | null = null;

type Props = {
  className?: string;
  url: string;
  center?: number[];
  showAreaName?: boolean;
  geopolitique?: boolean;
};

const ArcGis3DMap = ({
  className,
  url,
  center = [0, 0],
  showAreaName = false,
  geopolitique = false,
}: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);

  const [map, setMap] = useState<ArcGisMap>();
  const [view, setView] = useState<SceneView>();

  useEffect(() => {
    if (mapRef.current && !map) {
      const labelingInfo = showAreaName
        ? [
            new LabelClass({
              symbol: new TextSymbol({
                color: 'white',
              }),
              labelPlacement: 'above-center',
              labelExpressionInfo: {
                expression: '$feature.name',
              },
            }),
          ]
        : [];

      featureLayer = new FeatureLayer({
        url,
        outFields: ['*'],
        popupTemplate: {
          title: '{name}',
          content: '{description}',
        },
        labelingInfo,
        renderer: new SimpleRenderer({
          symbol: new SimpleFillSymbol({
            outline: {
              color: [255, 255, 255],
              width: 1,
            },
          }),
        }),
      });

      const aMap = new ArcGisMap({
        basemap: 'arcgis-dark-gray',
        layers: [featureLayer],
      });

      const sceneView = new SceneView({
        map: aMap,
        container: mapRef.current,
        center,
        zoom: 2,
        spatialReference: SpatialReference.WebMercator,
        popup: {
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,
          },
          viewModel: {
            includeDefaultActions: false,
          },
        },
      });
      sceneView.on('click', event => {
        if (highlighted) {
          highlighted.remove();
          highlighted = null;
        }
        sceneView.hitTest(event).then(response => {
          if (response.results.length > 0) {
            const firstResult = response.results[0];
            if (firstResult.type === 'graphic') {
              const graphic = firstResult.graphic.clone();
              graphic.setAttribute('geopolitique', geopolitique);
              dispatch(setAreaId(graphic.attributes.id));
              dispatch(setAreaGraphic(graphic.toJSON()));
            }
          }
        });
      });

      // Highlight the area if it's already selected
      if (areaId) {
        sceneView.whenLayerView(featureLayer).then(layerView => {
          const parcelQuery = {
            where: `id = '${areaId}'`,
            outFields: ['*'], // Attributes to return
            returnGeometry: true,
          };
          featureLayer?.queryFeatures(parcelQuery).then(response => {
            const feature = response.features.length > 0 ? response.features[0] : null;

            if (feature) {
              highlighted = layerView.highlight(feature);
            }
          });
        });
      }
      setMap(aMap);
      setView(sceneView);
    }
    return () => {
      if (map) {
        map.destroy();
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (view && !areaId) {
      view.goTo({ center });
    }
  }, [center]);

  useEffect(() => {
    if (featureLayer && view && areaId) {
      view?.whenLayerView(featureLayer).then(layerView => {
        const parcelQuery = {
          where: `id = '${areaId}'`,
          outFields: ['*'], // Attributes to return
          returnGeometry: true,
        };
        featureLayer?.queryFeatures(parcelQuery).then(response => {
          const feature = response.features.length > 0 ? response.features[0] : null;

          if (feature) {
            highlighted = layerView.highlight(feature);
          }
        });
      });
    }
  }, [areaId]);

  return <div className={clsx('arcgis-map', className)} ref={mapRef}></div>;
};

export default ArcGis3DMap;
