export const rad2deg = (radians: number) => {
  return (radians * 180) / Math.PI;
};

export const deg2rad = (deg: number) => {
  return (deg * Math.PI) / 180.0;
};
export const degreesLong = (radians: number) => {
  if (radians < -Math.PI || radians > Math.PI) {
    throw new RangeError('Longitude radians must be in range [-pi; pi].');
  }
  return rad2deg(radians);
};

export const degreesLat = (radians: number) => {
  if (radians < -Math.PI / 2 || radians > Math.PI / 2) {
    throw new RangeError('Latitude radians must be in range [-pi/2; pi/2].');
  }
  return rad2deg(radians);
};

export const computeBearingAngle = (p1: number[], p2: number[]) => {
  const dLon = deg2rad(p2[0] - p1[0]);
  const lat1 = deg2rad(p1[1]);
  const lat2 = deg2rad(p2[1]);
  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
  const bearing = (Math.atan2(y, x) * 180) / Math.PI;
  return (bearing + 360) % 360;
};
