import { QtmSlider } from '@qtm/react';
import { QtmSliderCustomEvent, SliderValue } from '@qtm/web-components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationSlider = () => {
  const dispatch = useAppDispatch();
  const start = useAppSelector(({ animation }) => animation.start);
  const end = useAppSelector(({ animation }) => animation.end);
  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const selection = useAppSelector(({ animation }) => animation.selection);

  if (!start || !end) {
    return null;
  }

  const onChange = (event: QtmSliderCustomEvent<SliderValue>) => {
    const value = event.detail as number;
    dispatch(setCurrentDate(value));
  };

  const minValue = start.valueOf();
  const maxValue = end.valueOf();

  const value = currentDate ? currentDate.valueOf() : minValue;

  const isDisabled = animationState === 'disabled';

  return (
    <QtmSlider
      className="qtm-animation-slider"
      min={minValue}
      max={maxValue}
      step={1000}
      value={value}
      onValueChanged={onChange}
      disabled={isDisabled}
    />
  );
};

export default AnimationSlider;
