import { QtmIcon } from '@qtm/react';

type Props = {
  rating: number;
  of?: number;
};

const Rating = ({ rating, of = 5 }: Props) => {
  const stars = Array.from({ length: of });
  return (
    <div className="flex">
      {stars.map((star, index) => (
        <QtmIcon
          key={index}
          icon={index < rating ? 'star' : 'grade'}
          size="large"
          variant="outlined"
        />
      ))}
    </div>
  );
};

export default Rating;
