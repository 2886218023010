const componentToHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbArrayToHex = (color: number[]) => {
  return '#' + componentToHex(color[0]) + componentToHex(color[1]) + componentToHex(color[2]);
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return [r, g, b];
  }
  return [0, 0, 0];
};
