import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import ColorVariable from '@arcgis/core/renderers/visualVariables/ColorVariable';
import ColorStop from '@arcgis/core/renderers/visualVariables/support/ColorStop';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import max from 'lodash/max';
import mean from 'lodash/mean';
import min from 'lodash/min';
import { Performance } from 'shared/model/resultFile.model';

export const resultClassBreakInfosColors = [
  '#1b1866',
  '#19366d',
  '#1a5b73',
  '#1c7a6f',
  '#1d804f',
  '#1e872b',
  '#3b8d1f',
  '#699420',
  '#9a9821',
  '#a16e22',
  '#a74023',
  '#ae243b',
  '#b42573',
];

export const getRenderer = (lonLatValues: Performance[], key: keyof Performance) => {
  const values = lonLatValues.map(item => item[key] as number);

  const minValue = min(values) as number;
  const maxValue = max(values) as number;

  const nbStep = resultClassBreakInfosColors.length;

  const step = (maxValue - minValue) / nbStep;

  const stops = resultClassBreakInfosColors.map((color, i) => {
    const value = i * step + minValue;

    return new ColorStop({
      color,
      label: value + '',
      value,
    });
  });

  const colorVariable = new ColorVariable({
    field: 'value',
    stops,
  });

  return new SimpleRenderer({
    symbol: new SimpleFillSymbol({
      outline: {
        color: 'lightgray',
        width: 0.5,
      },
    }),
    visualVariables: [colorVariable],
  });
};

const getPopupContent = (feature: any, other: any) => {
  const div = document.createElement('div');
  const atts = feature.graphic.attributes;

  const lat = `<div>lat ${atts.lat.toFixed(2)}, lon ${atts.lon.toFixed(2)}</div>`;
  const lon = `<div></div>`;
  const duration = formatTimePerfDuration(atts.value);
  const value = `<div>Revisit Time ${duration}</div>`;
  const content = value + lat + lon;

  div.innerHTML = '<div>' + content + '</div>';
  return div;
};

export const getPerformanceLayer = (
  values: Performance[],
  key: keyof Performance,
  showDetails: boolean,
) => {
  const source = makeGeometries(values, key, showDetails);

  const layer = new FeatureLayer({
    id: key,
    spatialReference: SpatialReference.WGS84,
    title: key,
    renderer: getRenderer(values, key),
    source,
    geometryType: 'polygon',
    objectIdField: 'id',
    opacity: 0.7,
    fields: [
      {
        name: 'value',
        type: 'double',
      },
      {
        name: 'lat',
        type: 'double',
      },
      {
        name: 'lon',
        type: 'double',
      },
    ],
    popupTemplate: {
      title: 'Average Revisit Time',
      content: getPopupContent,
      outFields: ['*'],
    },
  });
  return layer;
};

const makeGeometries = (
  lonLatValues: Performance[],
  key: keyof Performance,
  showDetails: boolean,
) => {
  const correctedValues = lonLatValues.map(item => {
    const lon = item.lon;

    if (lon > 180) {
      return {
        ...item,
        lon: lon - 360,
        value: item[key],
      };
    }
    return {
      ...item,
      lon,
      value: item[key],
    };
  });
  correctedValues.sort((v1, v2) => {
    const lat1 = v1.lat;
    const lat2 = v2.lat;

    const lon1 = v1.lon;
    const lon2 = v2.lon;

    if (lat1 === lat2) {
      if (lon1 < lon2) {
        return -1;
      } else if (lon2 < lon1) {
        return 1;
      }
      return 0;
    } else {
      if (lat1 < lat2) {
        return -1;
      }
      return 1;
    }
  });

  // console.table(correctedValues);
  let geometries: Graphic[] = [];
  if (!showDetails) {
    geometries = correctedValues.reduce((agg: Graphic[], value) => {
      const deltaLat = value.latSpan / 2;
      const minLat = value.lat - deltaLat;
      const maxLat = value.lat + deltaLat;

      const deltaLon = value.lonSpan / 2;
      const minLon = value.lon - deltaLon;
      const maxLon = value.lon + deltaLon;

      const a = [minLon, minLat];
      const b = [minLon, maxLat];
      const c = [maxLon, maxLat];
      const d = [maxLon, minLat];

      const polygon = new Polygon({
        rings: [[a, b, c, d]],
        spatialReference: SpatialReference.WGS84,
      });

      const currentGraphic = agg.find(g => {
        const geom = g.geometry as Polygon;

        const intersects = geometryEngine.intersects(polygon, geom);

        const gLat = g.getAttribute('lat');

        if (intersects && value.lat === gLat) {
          return true;
        }
        return false;
      });
      if (currentGraphic) {
        const agg = currentGraphic.getAttribute('agg') as number[];

        agg.push(value.value as number);

        const currentGeom = currentGraphic.geometry as Polygon;
        const rings = currentGeom.rings[0];

        const newRings = [rings[0], rings[1], c, d];
        const newPolygon = new Polygon({
          rings: [newRings],
          spatialReference: SpatialReference.WGS84,
        });
        currentGraphic.geometry = newPolygon;
      } else {
        const currentGraphic = new Graphic({
          geometry: polygon,
          attributes: {
            id: (agg.length - 1).toString(),
            agg: [value.value],
            lat: value.lat,
            lon: value.lon,
          },
        });

        agg.push(currentGraphic);
      }

      return agg;
    }, []);
    geometries.forEach(g => {
      const geom = g.geometry as Polygon;

      const centroid = geom.centroid;

      g.setAttribute('lat', centroid.latitude);
      g.setAttribute('lon', centroid.longitude);

      const agg = g.getAttribute('agg') as number[];
      const newValue = mean(agg);
      g.setAttribute('value', newValue);
    });
  } else {
    geometries = correctedValues.map((value, i) => {
      const deltaLon = value.lonSpan / 2;
      const minLon = value.lon - deltaLon;
      const maxLon = value.lon + deltaLon;

      const deltaLat = value.latSpan / 2;
      const minLat = value.lat - deltaLat;
      const maxLat = value.lat + deltaLat;

      const a = [minLon, minLat];
      const b = [minLon, maxLat];
      const c = [maxLon, maxLat];
      const d = [maxLon, minLat];

      const polygon = new Polygon({
        rings: [[a, b, c, d]],
        spatialReference: SpatialReference.WGS84,
      });

      return new Graphic({
        geometry: polygon,
        attributes: {
          id: i.toString(),
          value: value.value,
          lat: value.lat,
          lon: value.lon,
        },
      });
    });
  }
  return geometries;
};

export const formatTimePerfDuration = (value: number | undefined) => {
  if (isNumber(value)) {
    const duration = dayjs.duration(value, 'seconds');
    let format = 'HH [h] mm [min]';
    if (value > 86400) {
      format = 'DD [d] ' + format;
      const oneDay = dayjs.duration({ days: 1 });
      duration.subtract(oneDay);
    }

    return dayjs.utc(duration.asMilliseconds()).format(format);
  }
  return '';
};
