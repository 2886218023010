import { Dayjs } from 'dayjs';
import { IPointMetadata } from 'scichart/Charting/Model/IPointMetadata';
import { Satellite, SatelliteModel } from 'shared/model/resultFile.model';

export class AcquisitionOppMetadata implements IPointMetadata {
  public startDate: Dayjs;
  public isSelected: boolean = false;
  public satellite: Satellite | undefined;
  public satModel: SatelliteModel | undefined;
  public localDate: Dayjs;
  public previousOppDate: Dayjs | undefined;
  public previousSatDate: Dayjs | undefined;
  public defaultColor: string;

  constructor(
    startDate: Dayjs,
    satellite: Satellite | undefined,
    satelliteModel: SatelliteModel | undefined,
    localDate: Dayjs,
    previousOppDate: Dayjs | undefined,
    previousSatDate: Dayjs | undefined,
    defaultColor: string,
  ) {
    this.startDate = startDate;
    this.satellite = satellite;
    this.satModel = satelliteModel;
    this.localDate = localDate;
    this.previousOppDate = previousOppDate;
    this.previousSatDate = previousSatDate;
    this.defaultColor = defaultColor;
  }
}
