import classNames from 'classnames';
import { useState } from 'react';

import { AnimationSpeed, setAnimationSpeed } from 'shared/reducers/animationSlice';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

export const speedStep = 10;
const availableSpeeds = [16, 8, 4, 2, 1];

const AnimationSliderSpeedBtn = () => {
  const dispatch = useAppDispatch();

  const speed = useAppSelector(({ animation }) => animation.speed);

  const [show, setShow] = useState(false);

  const readableSpeed = speed.value / speedStep;

  const toggleShow = () => {
    setShow(!show);
  };

  const onSpeedSelect = (selectedSpeed: number) => () => {
    setShow(false);
    const newSpeed: AnimationSpeed = {
      value: selectedSpeed * speedStep,
      unit: 'seconds',
    };
    dispatch(setAnimationSpeed(newSpeed));
  };

  return (
    <div className="speed">
      {show && (
        <div className="speed-selector">
          {availableSpeeds.map(speed => (
            <div key={speed} className="speed-selector-item" onClick={onSpeedSelect(speed)}>
              <div className="hover-white">x{speed}</div>
            </div>
          ))}
        </div>
      )}
      <div className={classNames('speed-btn hover-white', { show })} onClick={toggleShow}>
        <div>Speed (x{readableSpeed})</div>
      </div>
    </div>
  );
};

export default AnimationSliderSpeedBtn;
