import { Filter } from '@loopback/filter';
import { api } from 'api/api';
import { FeatureCollection } from 'geojson';
import { AreaOfInterest } from 'shared/model/AreaOfInterest.model';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['areaOfInterests'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getAreas: builder.query<AreaOfInterest[], Filter<AreaOfInterest> | void>({
        query: filter => ({
          url: `/area-of-interests`,
          method: 'GET',
          params: filter
            ? {
                filter: JSON.stringify(filter),
              }
            : undefined,
        }),
        providesTags: ['areaOfInterests'],
      }),
      getAreasAsGeoJson: builder.query<FeatureCollection, Filter<AreaOfInterest> | void>({
        query: filter => ({
          url: `/area-of-interests`,
          method: 'GET',
          params: filter
            ? {
                filter: JSON.stringify(filter),
                format: 'geojson',
              }
            : {
                format: 'geojson',
              },
        }),
        providesTags: ['areaOfInterests'],
      }),
    }),
  });

export const { useGetAreasQuery, useGetAreasAsGeoJsonQuery, useLazyGetAreasQuery } = extendedApi;
