import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';

const baseUrl = import.meta.env.VITE_API_URL;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).app.token;
      if (token) {
        headers.set('token', token);
      } else {
        const bearerToken = (getState() as RootState).app.bearerToken;
        if (bearerToken) {
          headers.set('Authorization', 'Bearer ' + bearerToken);
        }
      }
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: builder => ({}),
});
