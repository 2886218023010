import ResultHeader from 'modules/results/ResultHeader';
import RevisitTimeHeatMap from 'modules/results/revisitTimeHeatMap/RevisitTimeHeatMap';

const ResultRevisitTimeHeatMap = () => {
  return (
    <div>
      <ResultHeader />
      <RevisitTimeHeatMap />
    </div>
  );
};

export default ResultRevisitTimeHeatMap;
