import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';
import { AnimationOpportunity } from 'modules/results/animation/AnimationOpportunities/AnimationOpportunitiesType';
import AnimationOpportunityItem from 'modules/results/animation/AnimationOpportunities/AnimationOpportunityItem';
import useGetResult from 'modules/results/result-hook';

import { useMemo } from 'react';

function AnimationOpportunitiesList() {
  const selection = useAppSelector(({ animation }) => animation.selection);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);

  const { file } = useGetResult();

  const poi = file?.pointOfInterest.find(poi => poi.id === selection?.id);

  const opportunities = useMemo(() => {
    const result: AnimationOpportunity[] = [];
    if (poi) {
      poi.satellites.forEach(sat => {
        sat.values.forEach(value => {
          result.push({
            ...value,
            satId: sat.satId,
            satName: sat.name,
          });
        });
      });
    }

    result.sort((a, b) => a.epoch.localeCompare(b.epoch));

    return result;
  }, [poi]);

  if (!poi) {
    return null;
  }

  const nextOpp = opportunities.find(opp => dayjs(opp.epoch).isAfter(currentDate));

  return (
    <div className="animation-opportunities-list">
      {opportunities.map((opp, i) => {
        let showDate = true;
        if (i > 0) {
          const prevOpp = opportunities[i - 1];
          const prevDate = new Date(prevOpp.epoch);
          const currDate = new Date(opp.epoch);
          showDate = prevDate.getDay() !== currDate.getDay();
        }

        const isNext = nextOpp?.epoch === opp.epoch;

        return (
          <AnimationOpportunityItem
            key={opp.epoch}
            opp={opp}
            poi={poi}
            showDate={showDate}
            isNext={isNext}
          />
        );
      })}
    </div>
  );
}

export default AnimationOpportunitiesList;
