import {
  QtmHeader,
  QtmHeaderDivider,
  QtmHeaderMenu,
  QtmHeaderMenuLeft,
  QtmIcon,
  QtmTab,
  QtmTabs,
} from '@qtm/react';
import { useMatch, useNavigate } from 'react-router-dom';
import BrandHeader from 'shared/compoments/BrandHeader';

const Header = () => {
  const navigate = useNavigate();

  const homeLocation = !!useMatch('/');
  const infoLocation = !!useMatch('/info/*');
  const satLocation = !!useMatch('/sat/*');
  const resultLocation = !!useMatch('/results/*');

  const navigateTo = (path: string) => () => {
    navigate(path);
  };

  return (
    <QtmHeader classes="" size="medium">
      <BrandHeader />
      <div className="hidden md:flex">
        <QtmHeaderDivider />
        <QtmHeaderMenu>
          <QtmHeaderMenuLeft>
            <QtmTabs fullHeight scrollHorizontally size="medium">
              <QtmTab active={homeLocation || infoLocation} onClick={navigateTo('/')}>
                <QtmIcon icon="home" />
              </QtmTab>
              {/*<QtmTab active={infoLocation} onClick={navigateTo('/info/1')}>
              <QtmIcon icon="info" />
  </QtmTab>*/}
              <QtmTab active={satLocation || resultLocation} onClick={navigateTo('/sat/area/type')}>
                <QtmIcon icon="satellite_alt" />
              </QtmTab>
            </QtmTabs>
          </QtmHeaderMenuLeft>
          {/*<QtmHeaderMenuRight>
            <ToggleDarkModeBtn />
          </QtmHeaderMenuRight>*/}{' '}
        </QtmHeaderMenu>
      </div>
    </QtmHeader>
  );
};

export default Header;
