import { QtmSlider, QtmTypography } from '@qtm/react';
import { QtmSliderCustomEvent, SliderValue } from '@qtm/web-components';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { defaultRatio, setRatio } from 'shared/reducers/selectionSlice';

import clsx from 'clsx';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import isNumber from 'lodash/isNumber';
import BottomButtonToolBar from 'shared/compoments/Buttons/BottomButtonToolBar';
import './ratio.css';

const availableRatios = [0, 25, 50, 75, 100];

const marks = availableRatios.map(value => ({
  label: `${value}%`,
  value,
}));

const ChooseSatRatio = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSm } = useTailwindBreakpoints();
  const areaId = useAppSelector(({ selection }) => selection.areaId);
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const ratio = useAppSelector(({ selection }) => selection.ratio);

  if (!isNumber(areaId) || !isNumber(revisitTime)) {
    return <Navigate to="/sat/area/type" />;
  }

  const onBack = async () => {
    if (!isNumber(ratio)) {
      await dispatch(setRatio(defaultRatio));
    }
    navigate('/sat/revisit/2');
  };

  const onNext = async () => {
    if (!isNumber(ratio)) {
      await dispatch(setRatio(defaultRatio));
    }
    navigate('/sat/constellations/4');
  };

  const onChange = (event: QtmSliderCustomEvent<SliderValue>) => {
    if (!Array.isArray(event.detail)) {
      dispatch(setRatio(event.detail));
    }
  };

  const ratioValue = isNumber(ratio) ? ratio : defaultRatio;

  const titleSize = isSm ? 'title-3' : 'title-2';
  const subTitleSize = isSm ? 'text-scale-5' : 'text-scale-4';

  return (
    <>
      <QtmTypography component={titleSize} classes="text-white-100">
        <span className="hidden md:inline">Set your Satellite Ratio</span>
        <span className="xs:inline md:hidden">Radar Satellites</span>
      </QtmTypography>
      <QtmTypography classes={`${subTitleSize} text-white-100 font-helvetica`}>
        You will set the radar satellites ratio in your constellation.
        <br />
        It's a key driver for you Revisit Time: The more radar
        <br />
        satellites you have, the better revisit time you will have.
      </QtmTypography>
      <div className="w-full px-l ">
        <div className={clsx('ratio-slider', { isSm })}>
          <QtmSlider
            anchorPoint={availableRatios[0]}
            color="primary"
            format-metric={null}
            legendPosition="top"
            marks={marks}
            min={availableRatios[0]}
            max={availableRatios[availableRatios.length - 1]}
            size="large"
            step={null}
            value={ratioValue}
            onValueChanged={onChange}
          />
          <div className="ratio-indicators">
            <QtmTypography classes="text-primary-300">{ratioValue}% Radar</QtmTypography>
            <QtmTypography classes="text-primary-300">{100 - ratioValue}% Optical</QtmTypography>
          </div>
        </div>
      </div>
      <BottomButtonToolBar onBack={onBack} onNext={onNext} />
    </>
  );
};

export default ChooseSatRatio;
