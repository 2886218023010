import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import Polyline from '@arcgis/core/geometry/Polyline';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SunLighting from '@arcgis/core/views/3d/environment/SunLighting';
import VirtualLighting from '@arcgis/core/views/3d/environment/VirtualLighting';
import SceneView from '@arcgis/core/views/SceneView';
import dayjs, { Dayjs } from 'dayjs';
import { propagate } from 'modules/results/animation/utils/propagation-utils';
import {
  getLeftRadarId,
  getRadarAcquiringGraphic,
  getRightRadarId,
  updateRadarAcquiringGraphic,
} from 'modules/results/animation/utils/radarAcquiring-utils';
import {
  getSatelliteSymbol,
  getSatelliteTailSymbol,
  updateModel3DOrientationWithAzimut,
} from 'modules/results/animation/utils/satellite-symbol';
import {
  KeplerianElement,
  KeplerianOrbitalElement,
  SatelliteModel,
} from 'shared/model/resultFile.model';
import { HRRColor } from 'shared/model/sateliteModel.constants';

import { computeBearingAngle } from 'utils/math.utils';
import { getSatColorFromModel } from 'utils/result-utils';

export const animationSatLayer = new GraphicsLayer({
  title: 'Satellites',
  elevationInfo: {
    mode: 'relative-to-ground',
  },
});

export const changeLighting = (view: SceneView, date: Dayjs) => {
  if (view.environment.lighting instanceof SunLighting) {
    view.environment.lighting.date = date.toDate();
  } else {
    view.environment.lighting = new SunLighting({
      date: date.toDate(),
      cameraTrackingEnabled: false,
    });
  }
};

export const resetLightning = (view: SceneView) => {
  if (view.environment.lighting instanceof SunLighting) {
    view.environment.lighting = new VirtualLighting();
  }
};

export const updateTail = async (orbit: Graphic | null, date: Dayjs) => {
  if (orbit) {
    const keplerianOrbitalElementStr = orbit.getAttribute('KeplerianOrbitalElement');
    if (keplerianOrbitalElementStr) {
      const satOribtalElem = JSON.parse(keplerianOrbitalElementStr);
      const from = date.subtract(5, 'minutes');
      orbit.geometry = getSatTail(satOribtalElem, from, date);
    }
  }
};

export const moveSatellites = (date: Dayjs) => {
  animationSatLayer.graphics.forEach(feature => {
    const type = feature.getAttribute('type');

    if (type === 'satellite') {
      const id = feature.getAttribute('id');
      const keplerianOrbitalElement = feature.getAttribute('KeplerianOrbitalElement');
      const orbitalElement = JSON.parse(keplerianOrbitalElement) as KeplerianOrbitalElement;

      const KeplerianElement = getKeplerianElementsAtDate(orbitalElement, date);
      const isRadar = feature.getAttribute('isRadar');

      const pos = propagate(KeplerianElement, date);
      const nextPos = propagate(KeplerianElement, date.add(1, 'minute'));

      const currentPoint = feature.geometry as Point;

      let az = computeBearingAngle(
        [pos.longitude, pos.latitude],
        [nextPos.longitude, nextPos.latitude],
      );
      if (az !== 0) {
        feature.setAttribute('az', az);
      } else {
        az = feature.getAttribute('az');
      }

      const currentLat = currentPoint.latitude;

      const ascending = pos.latitude - currentLat > 0;

      const point = new Point({
        ...pos,
        spatialReference: SpatialReference.WGS84,
      });

      const elevation = pos.z / 1000;
      feature.geometry = point;
      feature.setAttribute('longitude', pos.longitude);
      feature.setAttribute('latitude', pos.latitude);
      feature.setAttribute('z', pos.z);
      feature.setAttribute('az', az);
      feature.setAttribute('elevation', elevation.toFixed());
      feature.setAttribute('date', date.toISOString());
      feature.setAttribute('ascending', ascending);

      updateModel3DOrientationWithAzimut(feature, az);

      if (isRadar) {
        const satModelFirstAngle = feature.getAttribute('satModelFirstAngle');
        const satModelSecondAngle = feature.getAttribute('satModelSecondAngle');
        //const nextPos = propagate(KeplerianElement, date.add(1, 'minute'));

        const leftGraphic = animationSatLayer.graphics.find(
          g => g.getAttribute('id') === getLeftRadarId(id),
        );
        const rightGraphic = animationSatLayer.graphics.find(
          g => g.getAttribute('id') === getRightRadarId(id),
        );

        if (leftGraphic && rightGraphic) {
          updateRadarAcquiringGraphic(
            leftGraphic,
            rightGraphic,
            point,
            elevation,
            satModelFirstAngle,
            satModelSecondAngle,
            az,
            HRRColor,
          );
        }
      }
    } else if (type === 'orbit') {
      updateTail(feature, date);
    }
  });
};

const getKeplerianElementsAtDate = (
  KeplerianOrbitalElement: KeplerianOrbitalElement,
  currentDate: Dayjs,
) => {
  if (KeplerianOrbitalElement.keplerianElements.length === 0) {
    throw new Error('No orbital elements. Cannot propagate satellite !');
  }
  const first = KeplerianOrbitalElement.keplerianElements[0];
  const firstDate = dayjs(first.epoch);

  let found: {
    date: Dayjs;
    value: KeplerianElement;
  } = {
    date: firstDate,
    value: first,
  };

  KeplerianOrbitalElement.keplerianElements.forEach(keplerianElement => {
    const epoch = dayjs(keplerianElement.epoch);
    if (epoch.isSameOrBefore(currentDate) && epoch.isAfter(found.date)) {
      found.date = epoch;
      found.value = keplerianElement;
    }
  });

  return found.value;
};

export const getSatelliteGraphicsAtDate = (
  satModel: SatelliteModel | undefined,
  satOribtalElem: KeplerianOrbitalElement,
  date: Dayjs,
) => {
  let result: Graphic[] = [];

  const KeplerianElements = getKeplerianElementsAtDate(satOribtalElem, date);
  const pos = propagate(KeplerianElements, date);

  const nextPos = propagate(KeplerianElements, date.add(1, 'minute'));

  const az = computeBearingAngle(
    [pos.longitude, pos.latitude],
    [nextPos.longitude, nextPos.latitude],
  );

  const geometry = new Point({
    ...pos,
    spatialReference: SpatialReference.WGS84,
  });

  const color = getSatColorFromModel(satModel);
  const symbol = getSatelliteSymbol(satModel, pos.z / 1000, az);

  const firstAngle = satModel?.firstAngle ?? 0;
  const secondAngle = satModel?.secondAngle ?? 0;
  const isRadar = satModel?.isRadar ?? false;

  const satId = `${satOribtalElem.name}_${satOribtalElem.planeId}_${satOribtalElem.satId}`;

  const elevation = pos.z / 1000;

  const satGraphic = new Graphic({
    geometry: geometry,
    attributes: {
      ...pos,
      id: satId,
      name: satOribtalElem.name,
      type: 'satellite',
      date: date.valueOf(),
      KeplerianOrbitalElement: JSON.stringify(satOribtalElem),
      elevation: elevation.toFixed(),
      az: az,
      satModelColor: color,
      satModelFirstAngle: firstAngle,
      satModelSecondAngle: secondAngle,
      isRadar,
      inclinaison: KeplerianElements.inclination,
    },
    symbol,
    popupTemplate: {
      title: '{name}',
      content: '<div><div class="mb-2">{satModelName}</div><div>Elevation: {elevation} Km</div>',
    },
  });
  result.push(satGraphic);

  if (isRadar) {
    const radars = getRadarAcquiringGraphic(
      satId,
      geometry,
      elevation,
      firstAngle,
      secondAngle,
      az,
      HRRColor,
    );
    result = result.concat(radars);
  }

  const tailSymbol = getSatelliteTailSymbol(satModel);

  const satTail = new Graphic({
    geometry: new Polyline({
      paths: [],
      spatialReference: SpatialReference.WGS84,
    }),
    symbol: tailSymbol,
    attributes: {
      id: satId + '_orbit',
      name: satOribtalElem.name,
      type: 'orbit',
      KeplerianOrbitalElement: JSON.stringify(satOribtalElem),
    },
  });

  updateTail(satTail, date);
  result.push(satTail);

  return result;
};

export const getSatTail = (satOrbitalElement: KeplerianOrbitalElement, from: Dayjs, to: Dayjs) => {
  const KeplerianElement = getKeplerianElementsAtDate(satOrbitalElement, to);

  const geodetics = [];

  for (let m = from.clone(); m.isSameOrBefore(to); m = m.add(60, 'second')) {
    const result = propagate(KeplerianElement, m);
    geodetics.push([result.longitude, result.latitude, result.z]);
  }

  return new Polyline({
    paths: [geodetics],
    spatialReference: SpatialReference.WGS84,
  });
};
