import { QtmButton } from '@qtm/react';
import ResultHeader from 'modules/results/ResultHeader';
import ResultOpportunitiesSciChart from 'modules/results/opportunities/ResultOpportunitiesSciChart';
import useGetResult from 'modules/results/result-hook';
import { useEffect, useState } from 'react';
import { PointOfInterest } from 'shared/model/resultFile.model';

const ResultOpportunities = () => {
  const { file } = useGetResult();

  const [poi, setPoi] = useState<PointOfInterest>();

  const pois = file?.pointOfInterest ?? [];

  useEffect(() => {
    if (!poi && pois.length > 0) {
      const poiData = pois[0];
      if (poiData) {
        setPoi(poiData);
      }
    }
  }, [pois]);

  const onClick = (poiId: number) => () => {
    const poiData = pois.find(poi => poi.id === poiId);
    if (poiData) {
      setPoi(poiData);
    }
  };

  return (
    <div>
      <ResultHeader />
      <div className="mt-m">
        <div className="flex justify-center">
          {pois.map(aPoi => (
            <div key={aPoi.id} className="mr-l">
              <QtmButton
                size="medium"
                label={aPoi.name}
                color={poi?.id === aPoi.id ? 'success' : 'primary'}
                variant="outline"
                onClick={onClick(aPoi.id)}
              />
            </div>
          ))}
        </div>
        <div className="mt-m">
          {/*<ResultOpportunitiesChart poi={poi} />*/}
          <ResultOpportunitiesSciChart poi={poi} />
        </div>
      </div>
    </div>
  );
};

export default ResultOpportunities;
