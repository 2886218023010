import isNumber from 'lodash/isNumber';
import { SelectionState } from 'shared/reducers/selectionSlice';

export interface SatStepDef {
  id: number;
  label: string;
  match: string;
  first: string;
  //selectionKey?: keyof SelectionState;
  isCompleted?: (selection: SelectionState) => boolean;
}

export const satSteps: SatStepDef[] = [
  {
    id: 1,
    label: 'Area of interest',
    match: '/sat/area/*',
    first: '/sat/area/type',
    isCompleted: selection => !!selection.areaId,
  },
  {
    id: 2,
    label: 'Revisit Time',
    match: '/sat/revisit/*',
    first: '/sat/revisit/2',
    isCompleted: selection => !!selection.areaId && isNumber(selection.revisitTime),
  },
  {
    id: 3,
    label: 'Satellites',
    match: '/sat/ratio/*',
    first: '/sat/ratio/3',
    isCompleted: selection =>
      !!(selection.areaId && isNumber(selection.revisitTime) && isNumber(selection.ratio)),
  },
  {
    id: 4,
    label: 'Constellations',
    match: '/sat/constellations/*',
    first: '/sat/constellations/4',
    isCompleted: selection =>
      !!(selection.areaId && isNumber(selection.revisitTime) && isNumber(selection.ratio)),
  },
  {
    id: 5,
    label: 'Results',
    match: '/results/*',
    first: '/results/5',
  },
];
