import { QtmButton, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import { useNavigate } from 'react-router-dom';
import './info.css';

const Info = () => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();

  const onClick = () => {
    navigate('/sat/area/type');
  };

  const titleMt = isSm ? '' : 'mt-xl';
  const titleSize = isSm ? 'title-3' : 'title-2';

  return (
    <div className="info px-m">
      <div className={titleMt}>
        <QtmTypography component={titleSize} classes="text-white-100">
          Welcome in our <span className="whitespace-nowrap">ALL-IN-ONE application!</span>
        </QtmTypography>
      </div>
      <div className={clsx('description my-xl', { isSm })}>
        <QtmTypography className="text-white-100 font-helvetica">
          After answering a few questions about your needs, you will discover the most relevant
          constellation of Earth observation satellites suited to your missions.
        </QtmTypography>
      </div>
      <div className="actions">
        <QtmButton
          label="Let’s Start"
          rightIcon="arrow_forward_ios"
          color="primary"
          variant="outline"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Info;
