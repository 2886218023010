import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const defaultRevisitTime = 12;
export const defaultRatio = 50;

const initialState = {
  areaId: null as number | null,
  areaGraphic: null as any | null,
  revisitTime: null as number | null,
  ratio: null as number | null,
};

export type SelectionState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setAreaId: (state, action: PayloadAction<number | null>) => {
      state.areaId = action.payload;
    },
    setAreaGraphic: (state, action: PayloadAction<any | null>) => {
      state.areaGraphic = action.payload;
    },
    setRevisitTime: (state, action: PayloadAction<number>) => {
      state.revisitTime = action.payload;
    },
    setRatio: (state, action: PayloadAction<number>) => {
      state.ratio = action.payload;
    },
    reset: state => {
      state.areaId = null;
      state.areaGraphic = null;
      state.revisitTime = null;
      state.ratio = null;
    },
  },
});

export const { setAreaId, setAreaGraphic, setRevisitTime, setRatio, reset } = slice.actions;

export default slice.reducer;
