import Home from 'modules/Home/Home';
import Info from 'modules/info/Info';
import ChooseResult from 'modules/results/ChooseResult';
import { Result } from 'modules/results/Result';
import ResultAnimation from 'modules/results/animation/ResultAnimation';
import ResultOpportunities from 'modules/results/opportunities/ResultOpportunities';
import ResultRevisitTimeHeatMap from 'modules/results/revisitTimeHeatMap/ResultRevisitTimeHeatMap';
import Sat from 'modules/sat/Sat';
import ChooseArea from 'modules/sat/area/ChooseArea';
import ChooseAreaLonLat from 'modules/sat/area/ChooseAreaLonLat';
import ChooseAreaRegion from 'modules/sat/area/ChooseAreaRegion';
import ChooseAreaType from 'modules/sat/area/ChooseAreaType';
import ChooseConstellation from 'modules/sat/constellations/ChooseConstellationResult';
import ChoiceSummary from 'modules/sat/constellations/summary/ChoiceSummary';
import ChooseRevisit from 'modules/sat/revisit/ChooseRevisit';
import ChooseSatRatio from 'modules/sat/satRatio/ChooseRatio';

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';
import PrivateRoute from 'shared/routes/privateRoute';
import { history } from 'store';

const Routing: React.FC = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/info"
          element={
            <PrivateRoute>
              <Info />
            </PrivateRoute>
          }
        />
        <Route
          path="/sat"
          element={
            <PrivateRoute>
              <Sat />
            </PrivateRoute>
          }
        >
          <Route path="area" element={<ChooseArea />}>
            <Route path="type" element={<ChooseAreaType />} />
            <Route path="region" element={<ChooseAreaRegion />} />
            <Route path="lonlat" element={<ChooseAreaLonLat />} />
          </Route>
          <Route path="revisit/:id" element={<ChooseRevisit />} />
          <Route path="ratio/:id" element={<ChooseSatRatio />} />
          <Route path="constellations/:id" element={<ChoiceSummary />} />
          <Route path="constellations/:id/selection" element={<ChooseConstellation />} />
        </Route>
        <Route
          path="/results/:id"
          element={
            <PrivateRoute>
              <Result />
            </PrivateRoute>
          }
        >
          <Route path="main" element={<ChooseResult />} />
          <Route path="animation" element={<ResultAnimation />} />
          <Route path="opportunities" element={<ResultOpportunities />} />
          <Route path="revisit-time" element={<ResultRevisitTimeHeatMap />} />
        </Route>
      </Routes>
    </HistoryRouter>
  );
};

export default Routing;
