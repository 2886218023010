import Graphic from '@arcgis/core/Graphic';
import ArcGisMap from '@arcgis/core/Map';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import { Font } from '@arcgis/core/symbols';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';

import MapView from '@arcgis/core/views/MapView';
import clsx from 'clsx';
import { getPoiTextSymbol } from 'modules/results/animation/utils/poi-utils';
import { useEffect, useRef, useState } from 'react';
import { disableZooming } from 'utils/map.utils';

const zoiLayer = new GraphicsLayer();

export const poiMarkerSymbol = new PictureMarkerSymbol({
  url: `/assets/img/poi.svg`,
  width: '36px',
  height: '36px',
  yoffset: '18px',
});

const poiLayer = new FeatureLayer({
  title: 'Points of interests',
  geometryType: 'point',
  objectIdField: 'id',
  source: [],
  fields: [
    {
      name: 'name',
      type: 'string',
    },
  ],
  renderer: new SimpleRenderer({
    symbol: poiMarkerSymbol,
  }),
  labelingInfo: [
    new LabelClass({
      symbol: new TextSymbol({
        color: '#ffffff',
        haloColor: '#1b2a6f',
        haloSize: '1px',
        font: new Font({
          family: 'Montserrat',
          size: 12,
          style: 'normal',
          weight: 'normal',
        }),
      }),
      labelPlacement: 'above-center',
      labelExpressionInfo: {
        expression: '$feature.name',
      },
    }),
  ],
});

type Props = {
  className?: string;
  zoi: Graphic;
  pois: Graphic[];
  showAreaName?: boolean;
};

const ReadOnlyArcGis2DMap = ({ className, showAreaName = false, zoi, pois }: Props) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const [map, setMap] = useState<ArcGisMap>();

  useEffect(() => {
    if (mapRef.current && !map) {
      zoi.symbol = new SimpleFillSymbol({
        color: [40, 90, 100, 0.4],
        outline: {
          color: [40, 90, 100],
          width: 1,
        },
      });

      zoiLayer.add(zoi);

      const aMap = new ArcGisMap({
        basemap: 'arcgis-dark-gray',
        layers: [zoiLayer, poiLayer],
      });
      const mapView = new MapView({
        map: aMap,
        container: mapRef.current,

        constraints: {
          snapToZoom: false,
        },
        spatialReference: SpatialReference.WebMercator,
        popup: {
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,
          },
          viewModel: {
            includeDefaultActions: false,
          },
        },
      });
      mapView.when((view: MapView) => {
        const extent = zoi.geometry.extent.clone();
        mapView.goTo(extent.expand(1.6));
        disableZooming(view);
      });

      setMap(aMap);
    }
    return () => {
      zoiLayer.removeAll();

      if (map) {
        map.destroy();
      }
    };
  }, [mapRef]);

  useEffect(() => {
    pois.forEach(poi => {
      poi.symbol = getPoiTextSymbol(poi.attributes.name);
    });

    poiLayer.queryFeatures().then(result => {
      poiLayer.applyEdits({
        deleteFeatures: result.features,
        addFeatures: pois,
      });
    });
  }, [pois]);

  return <div className={clsx('arcgis-map', className)} ref={mapRef}></div>;
};

export default ReadOnlyArcGis2DMap;
