import { api } from 'api/api';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['log'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      addLog: builder.mutation<void, Omit<UsageLog, 'id'>>({
        query: body => ({
          url: `/usage-logs`,
          method: 'POST',
          body,
        }),
      }),
    }),
  });

export const { useAddLogMutation } = extendedApi;
