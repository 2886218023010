import { ThunkAction, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createBrowserHistory } from 'history';
import { Action, combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { api } from 'api/api';
import loggerMiddleware from 'utils/logger-middleware';
import AnimationReducer from './shared/reducers/animationSlice';
import AppReducer from './shared/reducers/appSlice';
import MapReducer from './shared/reducers/mapSlice';
import SelectionReducer from './shared/reducers/selectionSlice';

// Setup redux-first-history
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});
export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development' ? true : false,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([api.middleware, routerMiddleware, loggerMiddleware]),
  reducer: combineReducers({
    router: routerReducer,
    app: AppReducer,
    selection: SelectionReducer,
    animation: AnimationReducer,
    map: MapReducer,
    [api.reducerPath]: api.reducer,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
export const history = createReduxHistory(store);
