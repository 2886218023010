import { QtmProgressCircle, QtmTypography } from '@qtm/react';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import ConstellationResultLink from 'modules/results/ConstellationResultLink';
import useGetResult from 'modules/results/result-hook';
import SatStep from 'modules/sat/SatStep';
import { satSteps } from 'modules/sat/SatSteps-def';
import ConstellationResultCost from 'modules/sat/constellations/ConstellationResultCost';
import ConstellationResultPerformance from 'modules/sat/constellations/ConstellationResultPerformance';
import ConstellationResultRevisitTime from 'modules/sat/constellations/ConstellationResultRevisitTime';
import { useParams } from 'react-router-dom';
import Stepper from 'shared/compoments/stepper/Stepper';

const ChooseResult = () => {
  const { id } = useParams();
  const { isSm } = useTailwindBreakpoints();

  const { data, isLoading } = useGetResult();

  const constellationConfiguration = data?.constellationConfiguration ?? null;
  const cost = data?.constellationConfiguration?.cost ?? 0;

  const titleSize = isSm ? 'title-3' : 'title-2';
  const subTitleSize = isSm ? 'text-scale-5' : 'text-scale-4';

  return (
    <>
      <div className="result-stepper">
        <Stepper>
          {satSteps.map(step => (
            <SatStep key={step.id} def={step} />
          ))}
        </Stepper>
      </div>
      <QtmTypography component={titleSize} classes="text-white-100 my-xl">
        <span className="hidden md:inline">Constellation Results</span>
        <span className="xs:inline md:hidden">Results</span>
      </QtmTypography>
      {isLoading ? (
        <div className="h-full w-full flex flex-col items-center justify-center">
          <QtmProgressCircle classes="" track size="xlarge" />
        </div>
      ) : data && constellationConfiguration ? (
        <div className="h-full w-full">
          <QtmTypography classes={`${subTitleSize} text-white-100 font-helvetica`}>
            {constellationConfiguration.name}
          </QtmTypography>
          <div className="hidden md:flex justify-center">
            <div className="mr-xl">
              <ConstellationResultRevisitTime revisitTime={data.revisitTime} />
            </div>
            <div className="mr-xl">
              <ConstellationResultPerformance revisitTime={data.revisitTime} />
            </div>
            <ConstellationResultCost value={cost} />
          </div>
          <div className="mt-xl flex flex-col justify-center md:flex-row md:justify-around">
            <ConstellationResultLink
              icon="play_circle"
              text="3D Animation"
              to={`/results/${id}/animation`}
              inline={isSm}
            />
            <ConstellationResultLink
              icon="map"
              text="Revisit Time Heatmap"
              to={`/results/${id}/revisit-time`}
              inline={isSm}
            />
            <ConstellationResultLink
              icon="analytics"
              text="Acquisition Opportunities"
              to={`/results/${id}/opportunities`}
              inline={isSm}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChooseResult;
