import Graphic from '@arcgis/core/Graphic';
import Mesh from '@arcgis/core/geometry/Mesh';
import Point from '@arcgis/core/geometry/Point';
import { pointFromDistance } from '@arcgis/core/geometry/support/geodesicUtils';
import FillSymbol3DLayer from '@arcgis/core/symbols/FillSymbol3DLayer';
import MeshSymbol3D from '@arcgis/core/symbols/MeshSymbol3D';
import { deg2rad } from 'utils/math.utils';

const radarMeshSymbol = new MeshSymbol3D({
  symbolLayers: [new FillSymbol3DLayer()],
});

export const getLeftRadarId = (id: string) => id + '_left';
export const getRightRadarId = (id: string) => id + '_right';

export const getRadarAcquiringGraphic = (
  id: string,
  point: Point,
  elevation: number,
  radarAngle: number,
  excludeAngle: number,
  azimuth: number,
  satColor: number[],
) => {
  const graphics: Graphic[] = [];
  const color = [...satColor, 0.5];

  const elevationMeter = elevation * 1000;

  const maxdistanceMeter = elevationMeter * Math.tan(deg2rad(radarAngle));
  const mindistanceMeter = elevationMeter * Math.tan(deg2rad(excludeAngle - 5));

  /*const azs = [80, 100, 260, 280].map(nb => (azimuth + nb) % 360);
  
    const maxPoints = azs.map(az => pointFromDistance(point, maxdistanceMeter, az));
    const minPoints = azs.map(az => pointFromDistance(point, mindistanceMeter, az));*/

  const points: GroundPoints[] = [80, 100, 260, 280].map(nb => {
    const az = (azimuth + nb) % 360;
    return {
      max: pointFromDistance(point, maxdistanceMeter, az),
      min: pointFromDistance(point, mindistanceMeter, az),
    };
  });

  const g1 = new Graphic({
    geometry: getLeftRadarGeom(point, points, color),
    symbol: radarMeshSymbol,
    attributes: {
      id: getLeftRadarId(id),
      type: 'radarMesh',
    },
  });
  graphics.push(g1);

  const g2 = new Graphic({
    geometry: getRightRadarGeom(point, points, color),
    symbol: radarMeshSymbol,
    attributes: {
      id: getRightRadarId(id),
      type: 'radarMesh',
    },
  });
  graphics.push(g2);

  return graphics;
};

export const updateRadarAcquiringGraphic = (
  leftGraphic: Graphic,
  rightGraphic: Graphic,
  point: Point,
  elevation: number,
  radarAngle: number,
  excludeAngle: number,
  azimuth: number,
  satColor: number[],
) => {
  const color = [...satColor, 0.5];

  const elevationMeter = elevation * 1000;

  const maxdistanceMeter = elevationMeter * Math.tan(deg2rad(radarAngle));
  const mindistanceMeter = elevationMeter * Math.tan(deg2rad(excludeAngle - 5));

  const points: GroundPoints[] = [80, 100, 260, 280].map(nb => {
    const az = (azimuth + nb) % 360;
    return {
      max: pointFromDistance(point, maxdistanceMeter, az),
      min: pointFromDistance(point, mindistanceMeter, az),
    };
  });

  leftGraphic.geometry = getLeftRadarGeom(point, points, color);
  rightGraphic.geometry = getRightRadarGeom(point, points, color);
};

export const getLeftRadarGeom = (point: Point, points: GroundPoints[], color: number[]) => {
  const pointA = [points[0].max.longitude, points[0].max.latitude, 0];
  const pointB = [points[1].max.longitude, points[1].max.latitude, 0];
  const pointC = [points[1].min.longitude, points[1].min.latitude, 0];
  const pointD = [points[0].min.longitude, points[0].min.latitude, 0];
  const base = [pointA, pointB, pointC, pointD];

  return createPyramid(point, {
    base,
    material: {
      color,
    },
  });
};

export const getRightRadarGeom = (point: Point, points: GroundPoints[], color: number[]) => {
  const pointE = [points[2].max.longitude, points[2].max.latitude, 0];
  const pointF = [points[3].max.longitude, points[3].max.latitude, 0];
  const pointG = [points[3].min.longitude, points[3].min.latitude, 0];
  const pointH = [points[2].min.longitude, points[2].min.latitude, 0];
  const base = [pointE, pointF, pointG, pointH];

  return createPyramid(point, {
    base,
    material: {
      color,
    },
  });
};

interface IPyramidParams {
  base: number[][];
  material: __esri.MeshMaterialProperties;
}

const createPyramid = (pt: Point, params: IPyramidParams) => {
  const { base } = params;

  const top = [pt.x, pt.y, pt.z];

  // Vertex locations that make up the pyramid,
  // the first triple represents the top of the pyramid,
  // while the remaining 4 triples represent the base
  // of the pyramid
  let position: number[] = [...top];
  base.forEach(b => (position = position.concat(b)));

  return new Mesh({
    vertexAttributes: {
      position: position,
    },

    // The four triangles that make up the pyramid
    components: [
      { faces: [0, 1, 2], material: params.material },
      { faces: [0, 2, 3], material: params.material },
      { faces: [0, 3, 4], material: params.material },
      { faces: [0, 4, 1], material: params.material },
    ],

    spatialReference: pt.spatialReference,
  });
};

interface GroundPoints {
  max: Point;
  min: Point;
}
