import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import { Font, LabelSymbol3D, TextSymbol3DLayer } from '@arcgis/core/symbols';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import { FileResult } from 'shared/model/resultFile.model';

export const poiSymbol = new PictureMarkerSymbol({
  url: '/assets/img/poi.svg',
  width: '36px',
  height: '36px',
  yoffset: '18px',
});

export const getPoiTextSymbol = (text: string) =>
  new TextSymbol({
    color: 'white',
    haloSize: '1px',
    text,
    xoffset: 3,
    yoffset: 3,
    font: new Font({
      family: 'Montserrat',
      size: 12,
      style: 'normal',
      weight: 'normal',
    }),
  });

export const poiLayer = new FeatureLayer({
  id: 'poiLayerId',
  title: 'Points of interests',
  source: [],
  objectIdField: 'id',
  geometryType: 'point',
  spatialReference: SpatialReference.WGS84,

  fields: [
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'image',
      type: 'string',
    },
    {
      name: 'areaId',
      type: 'integer',
    },
  ],
  renderer: new SimpleRenderer({
    symbol: poiSymbol,
  }),
  popupTemplate: {
    title: '<div class="text-primary">{name}</div>',
    outFields: ['*'],
  },
  labelingInfo: [
    new LabelClass({
      symbol: new LabelSymbol3D({
        symbolLayers: [
          new TextSymbol3DLayer({
            material: {
              color: '#ffffff',
            },
            halo: {
              color: '#1b2a6f',
              size: '1px',
            },
            font: new Font({
              family: 'Montserrat',
              size: 12,
              style: 'normal',
              weight: 'normal',
            }),
          }),
        ],
      }),
      labelPlacement: 'above-center',
      labelExpressionInfo: {
        expression: '$feature.name',
      },
    }),
  ],
});

export const loadPois = (file: FileResult) => {
  const addFeatures = file.pointOfInterest.map((poi, index) => {
    const geometry = new Point({
      latitude: poi.latitude,
      longitude: poi.longitude,
    });

    return new Graphic({
      geometry: geometry,
      attributes: {
        name: poi.name,
        id: poi.id,
      },
    });
  });

  poiLayer.queryFeatures().then(result => {
    poiLayer.applyEdits({
      addFeatures,
      deleteFeatures: result.features,
    });
  });
};
