import useWindowDimensions from 'hooks/useWindowDimensions';

const useTailwindBreakpoints = () => {
  const { width } = useWindowDimensions();

  const isSm = width < 640;
  const isMd = width >= 640 && width < 768;
  const isLg = width >= 768 && width < 1024;
  const isXl = width >= 1024;

  return { isSm, isMd, isLg, isXl };
};

export default useTailwindBreakpoints;
