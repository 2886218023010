import { QtmButton, QtmIcon } from '@qtm/react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationBackwardAction = () => {
  const dispatch = useAppDispatch();

  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const start = useAppSelector(({ animation }) => animation.start);

  const isDisabled =
    animationState === 'disabled' || !currentDate || dayjs(currentDate).isSameOrBefore(start);

  const onBackwardClick = () => {
    if (currentDate && start && !isDisabled) {
      let backDate = dayjs(currentDate).subtract(15, 'minutes');
      if (backDate.isBefore(start)) {
        backDate = dayjs(start);
      }
      dispatch(setCurrentDate(backDate.valueOf()));
    }
  };

  const title = "Back 15''";

  return (
    <QtmButton
      size="large"
      color="primary"
      onClick={onBackwardClick}
      disabled={isDisabled}
      title={title}
    >
      <QtmIcon icon="history" />
    </QtmButton>
  );
};

export default AnimationBackwardAction;
