import { useAppSelector } from 'hooks/redux';
import { SatStepDef, satSteps } from 'modules/sat/SatSteps-def';
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import Step from 'shared/compoments/stepper/Step';

type Props = {
  def: SatStepDef;
};

const SatStep = ({ def }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const selection = useAppSelector(state => state.selection);

  const active = !!useMatch(def.match);

  const index = satSteps.findIndex(step => matchPath(step.match, pathname));

  const hasSelection = def.isCompleted ? def.isCompleted(selection) : false;

  const completed = def.id < index + 1 || hasSelection;

  const onClick = completed
    ? () => {
        navigate(def.first);
      }
    : undefined;

  return (
    <Step
      counter={def.id + ''}
      label={def.label}
      active={active}
      completed={completed}
      onClick={onClick}
    />
  );
};

export default SatStep;
