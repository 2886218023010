import clsx from 'clsx';

type Props = {
  counter: string;
  label: string;
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
};

const Step = ({ counter, label, active = false, completed = false, onClick }: Props) => {
  return (
    <div
      className={clsx('stepper-item', { completed, active, clickable: onClick })}
      onClick={onClick}
    >
      <div className="step-counter">{counter}</div>
      <div className="step-name hidden md:block">{label}</div>
    </div>
  );
};

export default Step;
