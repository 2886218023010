import { QtmIcon, QtmTypography } from '@qtm/react';
import { useNavigate } from 'react-router-dom';

type Props = {
  icon: string;
  text: string;
  to: string;
  inline?: boolean;
};

const ConstellationResultLink = ({ icon, text, to, inline = false }: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(to);
  };

  if (inline) {
    return (
      <div className="flex flex-col items-center mb-l cursor-pointer" onClick={onClick}>
        <QtmIcon icon={icon} size="xxlarge" />
        <QtmTypography classes="text-scale-4 text-white-100 ml-m">{text}</QtmTypography>
      </div>
    );
  }

  return (
    <div className="constellation-result-link" onClick={onClick}>
      <QtmIcon icon={icon} size="xxlarge" />
      <QtmTypography classes="text-scale-4 text-white-100 mt-s">{text}</QtmTypography>
    </div>
  );
};

export default ConstellationResultLink;
