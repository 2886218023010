import '@arcgis/core/assets/esri/themes/dark/main.css';
import esriConfig from '@arcgis/core/config';
import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import App from 'App';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import './index.css';

import { MsalProvider } from '@azure/msal-react';
import { SciChartSurface } from 'scichart';

//ini Azure
const configuration: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_TENANT}`,
    redirectUri: document.location.origin,
    clientId: import.meta.env.VITE_CLIENT_ID,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(configuration);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// init arcgisjs
esriConfig.assetsPath = '/assets';
esriConfig.apiKey = import.meta.env.VITE_ESRI_API_KEY;
//console.log(esriConfig.apiKey);

const appName = import.meta.env.VITE_APP_NAME;
console.log(appName + ' v' + APP_VERSION);

// init dayjs
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// init sciChart
SciChartSurface.configure({
  wasmUrl: '/assets/scichart/scichart2d.wasm',
  dataUrl: '/assets/scichart/scichart2d.data',
});
SciChartSurface.setRuntimeLicenseKey(import.meta.env.VITE_SCI_CHART_LICENSE);

const root = ReactDOM.createRoot(document.getElementById('root')!);

// Production
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
);
