import clsx from 'clsx';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import React from 'react';
import Header from '../Header';
import './layout.css';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { isSm } = useTailwindBreakpoints();

  return (
    <div>
      <Header />
      <div className="flex justify-center px-m">
        <div className={clsx('layout w-full md:w-3/4', { isSm })}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
