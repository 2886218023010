import { QtmIcon, QtmTypography } from '@qtm/react';
import dayjs from 'dayjs';
import { availableRevisitTimes } from 'modules/sat/revisit/ChooseRevisit';
import Rating from 'shared/compoments/rating/Rating';

type Props = {
  revisitTime: number;
  inline?: boolean;
};

const ConstellationResultPerformance = ({ revisitTime, inline = false }: Props) => {
  const duration = dayjs.duration(revisitTime * 1000).asHours();

  const roundDuration = Math.round(duration);

  const index = availableRevisitTimes.reduce((acc, curr, index) => {
    if (roundDuration >= curr) {
      acc = index + 1;
    }
    return acc;
  }, 0);

  const value = availableRevisitTimes.length - index;

  if (inline) {
    return (
      <div className="flex justify-between items-center">
        <div className="flex">
          <QtmIcon icon="speed" size="large" />
          <QtmTypography className="pl-m">Performance:</QtmTypography>
        </div>
        <Rating rating={value} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex">
        <QtmIcon icon="speed" size="large" />
        <div className="ml-s">
          <QtmTypography>Performance:</QtmTypography>
        </div>
      </div>
      <div>
        <Rating rating={value} />
      </div>
    </div>
  );
};

export default ConstellationResultPerformance;
