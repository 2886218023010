import { FileResult, SatelliteModel } from 'shared/model/resultFile.model';
import { HROColor, HRRColor, NoColor } from 'shared/model/sateliteModel.constants';

export const getSatModelFromSatModelId = (result: FileResult, satModelId: number) => {
  return result.satelliteModels.find(satModel => satModel.id === satModelId);
};

export const getSatModel = (result: FileResult, satName: string) => {
  const satModelId = result.keplerianOrbitalElements.find(
    kep => kep.name === satName,
  )?.satelliteModelId;

  if (satModelId === undefined) {
    return undefined;
  }
  return getSatModelFromSatModelId(result, satModelId);
};

export const getSatColorFromModel = (satModel: SatelliteModel | undefined) => {
  if (!satModel) return NoColor;
  return satModel.isRadar ? HRRColor : HROColor;
};

export const getSatColor = (result: FileResult, satName: string) => {
  const satModel = getSatModel(result, satName);
  return getSatColorFromModel(satModel);
};
