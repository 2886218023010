import { useAddLogMutation } from 'api/UsageLogEndPoints';
import { useAppSelector } from 'hooks/redux';
import isNumber from 'lodash/isNumber';
import { useEffect } from 'react';

const useCriteriaLog = () => {
  const areaId = useAppSelector(({ selection }) => selection.areaId);
  const revisitTime = useAppSelector(({ selection }) => selection.revisitTime);
  const ratio = useAppSelector(({ selection }) => selection.ratio);

  const [addLog] = useAddLogMutation();

  useEffect(() => {
    if (isNumber(areaId) && isNumber(revisitTime) && isNumber(ratio)) {
      addLog({
        date: new Date().toISOString(),
        type: 'CRITERIA',
        content: {
          areaId: areaId,
          ratio: ratio,
          revisitTime: revisitTime,
        },
      });
    }
  }, []);
};

export default useCriteriaLog;
