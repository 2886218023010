import { Filter } from '@loopback/filter';
import { api } from 'api/api';
import { ConstellationResult } from 'shared/model/ConstellationResult.model';
import { FileResult } from 'shared/model/resultFile.model';

export const extendedApi = api
  .enhanceEndpoints({
    addTagTypes: ['constellationResults', 'constellationResult', 'fileResult', 'geojsonResult'],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: builder => ({
      getConstellationsResults: builder.query<
        ConstellationResult[],
        Filter<ConstellationResult> | void
      >({
        query: filter => ({
          url: `/constellation-results`,
          method: 'GET',
          params: filter
            ? {
                filter: JSON.stringify(filter),
              }
            : undefined,
        }),
        providesTags: ['constellationResults'],
      }),
      getConstellationsResult: builder.query<
        ConstellationResult,
        { id: string | number | undefined; filter: Filter<ConstellationResult> }
      >({
        query: ({ id, filter }) => ({
          url: `/constellation-results/${id}`,
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        }),
        providesTags: ['constellationResult'],
      }),
      getFileResult: builder.query<FileResult, string>({
        query: id => ({
          url: `/constellation-results/${id}/file-result`,
          method: 'GET',
        }),
        providesTags: ['fileResult'],
      }),
      getGeoJsonFileResult: builder.query<FileResult, string>({
        query: id => ({
          url: `/constellation-results/${id}/geojson`,
          method: 'GET',
        }),
        providesTags: ['geojsonResult'],
      }),
    }),
  });

export const {
  useGetConstellationsResultsQuery,
  useGetConstellationsResultQuery,
  useGetFileResultQuery,
  useGetGeoJsonFileResultQuery,
} = extendedApi;
