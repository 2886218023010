import clsx from 'clsx';
import './stepper.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Stepper = (props: Props) => {
  return <div className={clsx('stepper')}>{props.children}</div>;
};

export default Stepper;
