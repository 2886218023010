import classnames from 'classnames';

export const resultMapColors = ['#1B1866', '#53B8D9', '#51A293', '#F5A030', '#B42573'];

import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import ColorGradient from 'shared/compoments/Legend/ColorGradient';
import './color-legend.css';

const ColorLegend = () => {
  const { isSm } = useTailwindBreakpoints();
  const legendInfo = useAppSelector(({ map }) => map.legendInfo);

  if (!legendInfo) {
    return null;
  }

  const { values, name, unit = '' } = legendInfo;

  const colors = resultMapColors;

  return (
    <div className={classnames('color-legend', { isSm })}>
      <div className="units">
        {values.map((value, index) => (
          <div key={value + '_' + index} className="text">
            {value}
          </div>
        ))}
      </div>
      <ColorGradient className="gradient" colors={colors} />
      <div className="text-center">
        {name}
        {unit && <span>&nbsp;({unit})</span>}
      </div>
    </div>
  );
};
export default ColorLegend;
