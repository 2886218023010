import get from 'lodash/get';

export const arrayRotate = (arr: any[], reverse = false) => {
  const result = [...arr];
  if (reverse) result.unshift(result.pop());
  else result.push(result.shift());
  return result;
};

export const sortByString = (fieldName: string) => (o1: any, o2: any) => {
  const str1 = o1[fieldName]?.toLowerCase();
  const str2 = o2[fieldName]?.toLowerCase();
  return str1.localeCompare(str2);
};

export const sortByName = () => sortByString('name');
export const sortByLabel = () => sortByString('label');

export const sortByDate = (fieldName: string) => (o1: any, o2: any) => {
  const d1 = new Date(o1[fieldName]);
  const d2 = new Date(o2[fieldName]);
  return d2.getTime() - d1.getTime();
};

export const sortString = (s1: string, s2: string) => {
  const l1 = s1.toLowerCase();
  const l2 = s2.toLowerCase();
  return l1.localeCompare(l2);
};

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const removeItemByProperty = <T>(arr: Array<T>, property: string, value: any): Array<T> => {
  const index = arr.findIndex(item => get(item, property) === value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const removeItemById = <T>(arr: Array<T>, value: any): Array<T> => {
  return removeItemByProperty(arr, 'id', value);
};

export const removeItemByName = <T>(arr: Array<T>, value: any): Array<T> => {
  return removeItemByProperty(arr, 'name', value);
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
