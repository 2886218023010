import { useAddLogMutation } from 'api/UsageLogEndPoints';
import { useEffectOnce } from 'react-use';

type Props = {
  children: React.ReactNode;
};

const LogAuthentication = ({ children }: Props) => {
  const [addLog] = useAddLogMutation();

  useEffectOnce(() => {
    addLog({
      date: new Date().toISOString(),
      type: 'CONNECTION',
      content: {},
    });
    setInterval(() => {
      if (document.hasFocus()) {
        addLog({
          date: new Date().toISOString(),
          type: 'CONNECTION',
          content: {},
        });
      }
    }, 30000);
  });

  return <>{children}</>;
};

export default LogAuthentication;
