import { QtmButton } from '@qtm/react';
import { useNavigate } from 'react-router-dom';
import './home.css';

const Home = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/info');
  };

  return (
    <div className="home w-full sm:w-3/4 mt-4xl">
      <img src="/allInOne.png" alt="allInOne" />
      <div className="actions mt-l">
        <img src="/logo_dark.svg" className="my-m" width={180} />
        <QtmButton
          label="Let’s Start"
          rightIcon="arrow_forward_ios"
          color="primary"
          variant="outline"
          className="pt-m"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Home;
