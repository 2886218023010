import Graphic from '@arcgis/core/Graphic';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import { useGetPoiOfArea } from 'modules/sat/constellations/summary/AreaHook';
import ReadOnlyArcGis2DMap from 'shared/compoments/map/ReadOnlyArcGis2DMap';

const AreaSummary = () => {
  const areaGraphic = useAppSelector(({ selection }) => selection.areaGraphic);
  const pois = useGetPoiOfArea(areaGraphic);
  const { isSm } = useTailwindBreakpoints();

  if (!areaGraphic) {
    return null;
  }

  const graphic = Graphic.fromJSON(areaGraphic);

  return (
    <ReadOnlyArcGis2DMap className={clsx('map-2D summary', { isSm })} zoi={graphic} pois={pois} />
  );
};

export default AreaSummary;
