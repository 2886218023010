import { SciChartSurface } from 'scichart';
import { MouseWheelZoomModifier } from 'scichart/Charting/ChartModifiers/MouseWheelZoomModifier';
import { ZoomExtentsModifier } from 'scichart/Charting/ChartModifiers/ZoomExtentsModifier';
import { ZoomPanModifier } from 'scichart/Charting/ChartModifiers/ZoomPanModifier';
import { IThemeProvider } from 'scichart/Charting/Themes/IThemeProvider';

// const backgroundcolor = 'rgba(83, 184, 217, 0.1)';
const backgroundcolor = 'rgba(14, 17, 45, 0.1)';
//const backgroundcolor = '#0E112D';

export const sciChartTheme: IThemeProvider = {
  axisBorder: 'Transparent',
  axisTitleColor: '#1B2A6F',
  annotationsGripsBackroundBrush: 'white',
  annotationsGripsBorderBrush: 'white',
  axis3DBandsFill: '#1F3D6833',
  axisBandsFill: '#1F3D6833',
  axisPlaneBackgroundFill: 'Transparent',
  columnFillBrush: 'white',
  columnLineColor: 'white',
  cursorLineBrush: '#1B2A6F99',
  defaultColorMapBrush: [
    { offset: 0, color: 'DarkBlue' },
    { offset: 0.5, color: 'CornflowerBlue' },
    { offset: 1, color: '#FF22AA' },
  ],
  downBandSeriesFillColor: '#52CC5490',
  downBandSeriesLineColor: '#E26565FF',
  downBodyBrush: 'white',
  downWickColor: 'white',
  gridBackgroundBrush: 'white',
  gridBorderBrush: 'white',
  labelBackgroundBrush: '#1B2A6FAA',
  labelBorderBrush: '#1B2A6F',
  labelForegroundBrush: '#EEEEEE',
  legendBackgroundBrush: '#1D2C35',
  lineSeriesColor: 'white',
  loadingAnimationBackground: '#0D213A',
  loadingAnimationForeground: '#1B2A6F',
  majorGridLineBrush: '#1F3D68',
  minorGridLineBrush: '#102A47',
  mountainAreaBrush: 'white',
  mountainLineColor: 'white',
  overviewFillBrush: 'white',
  planeBorderColor: 'white',
  rolloverLineBrush: '#FD9F2533',
  rubberBandFillBrush: '#99999933',
  rubberBandStrokeBrush: '#99999977',
  sciChartBackground: backgroundcolor,
  scrollbarBackgroundBrush: 'white',
  scrollbarBorderBrush: 'white',
  scrollbarGripsBackgroundBrush: 'white',
  scrollbarViewportBackgroundBrush: 'white',
  scrollbarViewportBorderBrush: 'white',
  shadowEffectColor: 'white',
  textAnnotationBackground: '#1B2A6FAA',
  textAnnotationForeground: '#EEEEEE',
  tickTextBrush: '#1B2A6F',
  upBandSeriesFillColor: 'white',
  upBandSeriesLineColor: 'white',
  upBodyBrush: '#1B2A6FA0',
  upWickColor: '#1B2A6F',
  chartTitleColor: 'white',
};

export const addChartNotifiers = (chartSurface: SciChartSurface) => {
  chartSurface.chartModifiers.add(new MouseWheelZoomModifier());
  chartSurface.chartModifiers.add(new ZoomPanModifier());

  chartSurface.chartModifiers.add(
    new ZoomExtentsModifier({
      isAnimated: true,
      animationDuration: 400,
    }),
  );

  // sciChartSurface.chartModifiers.add(new RubberBandXyZoomModifier());
  //sciChartSurface.chartModifiers.add(new ZoomExtentsModifier());
};
