import { useIsAuthenticated } from '@azure/msal-react';
import { useAppSelector } from 'hooks/redux';
import { Navigate } from 'react-router-dom';
import Layout from 'shared/compoments/layout/Layout';

interface IProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: IProps) => {
  const tokenAuthentication = useAppSelector(state => state.app.token !== '');
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated || tokenAuthentication) {
    return <Layout>{children}</Layout>;
  }
  return <Navigate to="/" />;
};

export default PrivateRoute;
