import Point from '@arcgis/core/geometry/Point';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AnimationOpportunity } from 'modules/results/animation/AnimationOpportunities/AnimationOpportunitiesType';

import { useEffect, useRef, useState } from 'react';
import { PointOfInterest } from 'shared/model/resultFile.model';

import { setCurrentDate } from 'shared/reducers/animationSlice';
import { getLocaleDateFromPosition } from 'utils/map.utils';

type Props = {
  opp: AnimationOpportunity;
  poi: PointOfInterest;
  showDate?: boolean;
  isNext?: boolean;
};

const AnimationOpportunityItem = ({ opp, showDate = true, poi, isNext = false }: Props) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const currentDate = useAppSelector(({ animation }) => animation.currentDate);

  const [oppDate, setOppDate] = useState<Dayjs>();

  useEffect(() => {
    const loadLocalDate = async () => {
      if (poi) {
        const point = new Point({
          latitude: poi.latitude,
          longitude: poi.longitude,
        });
        const localDate = await getLocaleDateFromPosition(dayjs(opp.epoch), point);
        setOppDate(localDate);
      }
    };
    loadLocalDate();
  }, [poi, opp]);

  useEffect(() => {
    if (isNext && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [isNext]);

  const onClick = () => {
    const oppDate = new Date(opp.epoch);
    dispatch(setCurrentDate(oppDate.valueOf()));
  };

  const dateFormatted = oppDate && showDate ? oppDate.format('DD/MM/YYYY') : ' ';
  const timeFormatted = oppDate ? oppDate.format('HH:mm') : '';

  const selected = currentDate === new Date(opp.epoch).getTime();
  const before = currentDate && currentDate > new Date(opp.epoch).getTime();
  const after = currentDate && currentDate < new Date(opp.epoch).getTime();

  return (
    <div
      ref={ref}
      className={classnames('opportunity-item', {
        selected,
        'animate-pulse': isNext,
        before,
        after,
        isNext,
      })}
      onClick={onClick}
    >
      <div className="opp-date">{dateFormatted}</div>
      <div>{timeFormatted}</div>
      <div className="sat-name">{opp.satName}</div>
    </div>
  );
};

export default AnimationOpportunityItem;
