import { watch } from '@arcgis/core/core/reactiveUtils';

import { useEffect } from 'react';
import { setAnimationState, toNextDate } from 'shared/reducers/animationSlice';

import SceneView from '@arcgis/core/views/SceneView';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import {
  changeLighting,
  moveSatellites,
  resetLightning,
} from 'modules/results/animation/utils/animation-utils';
import AnimationSlider from './AnimationSlider';
import AnimationSliderActions from './AnimationSliderActions';
import AnimationSliderCurrentDate from './AnimationSliderCurrentDate';
import './animationSlider.css';

const refreshRate = 60;

let animationTimeout: ReturnType<typeof setTimeout> | null = null;

let handle: IHandle | null = null;

type Props = {
  view: SceneView;
};

const AnimationControlPanel = ({ view }: Props) => {
  const dispatch = useAppDispatch();
  const { isSm } = useTailwindBreakpoints();

  const start = useAppSelector(({ animation }) => animation.start);
  const end = useAppSelector(({ animation }) => animation.end);
  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);

  useEffect(() => {
    if (currentDate) {
      const current = dayjs(currentDate);
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
      view?.closePopup();
      if (current.isSameOrBefore(end)) {
        moveSatellites(current);
        changeLighting(view, current);
        if (animationState === 'playing') {
          animationTimeout = setTimeout(() => {
            dispatch(toNextDate());
          }, refreshRate);
        }
      } else {
        if (animationTimeout) {
          clearTimeout(animationTimeout);
        }
        changeLighting(view, current);
        dispatch(setAnimationState('pause'));
      }
    }
  }, [currentDate, animationState]);

  useEffect(() => {
    if (animationState === 'playing') {
      handle = watch(
        () => view?.interacting,
        interacting => {
          if (interacting) {
            if (animationTimeout) {
              clearTimeout(animationTimeout);
            }
          } else {
            dispatch(toNextDate());
          }
        },
      );
    } else {
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
      handle?.remove();
    }
    return () => {
      resetLightning(view);
      handle?.remove();
      if (animationTimeout) {
        clearTimeout(animationTimeout);
      }
    };
  }, [animationState]);

  if (!start || !end) {
    return null;
  }

  return (
    <div className={clsx('animation-slider', { isSm })}>
      <AnimationSliderCurrentDate />
      <div className="slider">
        <AnimationSlider />
      </div>
      <AnimationSliderActions view={view} />
    </div>
  );
};

export default AnimationControlPanel;
