import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LegendInfo {
  values: string[];
  name: string;
  unit?: string;
}

const initialState = {
  legendInfo: null as LegendInfo | null,
};

export type MapState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setLegendInfo: (state, action: PayloadAction<LegendInfo | null>) => {
      state.legendInfo = action.payload;
    },
  },
});

export const { setLegendInfo } = slice.actions;

export default slice.reducer;
