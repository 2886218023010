import SatStep from 'modules/sat/SatStep';
import { satSteps } from 'modules/sat/SatSteps-def';
import { Outlet } from 'react-router-dom';
import Stepper from 'shared/compoments/stepper/Stepper';

import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import './sat.css';

export const Sat = () => {
  const { isSm } = useTailwindBreakpoints();
  return (
    <div className="sat pt-m md:pt-3xl">
      <Stepper>
        {satSteps.map(step => (
          <SatStep key={step.id} def={step} />
        ))}
      </Stepper>
      <div className="sat-content mt-s md:mt-xl">
        <Outlet />
      </div>
    </div>
  );
};

export default Sat;
