import { QtmButton, QtmIcon } from '@qtm/react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationForwardAction = () => {
  const dispatch = useAppDispatch();

  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const end = useAppSelector(({ animation }) => animation.end);

  const isDisabled =
    animationState === 'disabled' || !currentDate || dayjs(currentDate).isSameOrAfter(end);

  const onForwardClick = () => {
    if (currentDate && end && !isDisabled) {
      let nextDate = dayjs(currentDate).add(15, 'minutes');
      if (nextDate.isAfter(end)) {
        nextDate = dayjs(end);
      }
      dispatch(setCurrentDate(nextDate.valueOf()));
    }
  };

  const title = "Forward 15''";

  return (
    <QtmButton
      size="large"
      color="primary"
      onClick={onForwardClick}
      disabled={isDisabled}
      title={title}
    >
      <QtmIcon icon="update" />
    </QtmButton>
  );
};

export default AnimationForwardAction;
