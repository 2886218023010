import dayjs, { Dayjs } from 'dayjs';
import isNumber from 'lodash/isNumber';

export const computeAndFormatDuration = (date1: Dayjs, date2: Dayjs) => {
  const diff = date1.diff(date2);
  const duration = dayjs.duration(diff);
  return duration.format('HH:mm:ss');
};
export const addMinutes = (date: Date, minutes: number) =>
  new Date(date.getTime() + minutes * 60000);
export const removeMinutes = (date: Date, minutes: number) =>
  new Date(date.getTime() - minutes * 60000);

export const formatTimePerfDuration = (value: number | undefined) => {
  if (isNumber(value)) {
    const duration = dayjs.duration(value, 'seconds');
    let format = 'HH:mm:ss';
    if (value > 86400) {
      format = 'DD [day(s)]  HH:mm:ss';
      const oneDay = dayjs.duration({ days: 1 });
      duration.subtract(oneDay);
    }

    return dayjs.utc(duration.asMilliseconds()).format(format);
  }
  return '';
};
