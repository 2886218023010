import Point from '@arcgis/core/geometry/Point';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import { PointOfInterest } from 'shared/model/resultFile.model';
import { getLocaleDateFromPosition } from 'utils/map.utils';

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';

const AnimationSliderCurrentDate = () => {
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const selection = useAppSelector(({ animation }) => animation.selection);
  const start = useAppSelector(({ animation }) => animation.start);
  const end = useAppSelector(({ animation }) => animation.end);

  const [info, setInfo] = useState<{
    offset: string;
    minTitle: string;
    maxTitle: string;
    current: string;
  }>();

  const minDate = dayjs(start);
  const maxDate = dayjs(end);

  const min = minDate.format(dateFormat);
  const max = maxDate.format(dateFormat);

  useEffect(() => {
    if (currentDate) {
      const date = dayjs(currentDate);

      const loadInfo = async (poi?: PointOfInterest | null) => {
        if (poi && start && end) {
          const point = new Point({
            latitude: poi.latitude,
            longitude: poi.longitude,
          });

          const localDate = await getLocaleDateFromPosition(date, point);
          const minLocalDate = await getLocaleDateFromPosition(minDate, point);
          const maxLocalDate = await getLocaleDateFromPosition(maxDate, point);

          setInfo({
            offset: '(UTC' + localDate.format('Z') + ')',
            minTitle: minLocalDate.format(dateTimeFormat),
            maxTitle: maxLocalDate.format(dateTimeFormat),
            current: localDate.format(dateTimeFormat),
          });
        } else {
          const minTitle = minDate ? minDate.utc().format(dateTimeFormat) : '';
          const maxTitle = maxDate ? maxDate.utc().format(dateTimeFormat) : '';
          setInfo({
            offset: '',
            minTitle,
            maxTitle,
            current: date.format(dateTimeFormat),
          });
        }
      };

      loadInfo(selection);
    }
  }, [selection, currentDate]);

  return (
    <div className="title">
      <div className="smaller" title={info?.minTitle}>
        {min}
      </div>
      <div className="font-weight-bold">
        {info?.current} {info?.offset}
      </div>
      <div className="smaller" title={info?.maxTitle}>
        {max}
      </div>
    </div>
  );
};

export default AnimationSliderCurrentDate;
