import { Outlet } from 'react-router-dom';

import { QtmProgressCircle } from '@qtm/react';
import useGetResult from 'modules/results/result-hook';
import './result.css';

export const Result = () => {
  const { data, isLoading } = useGetResult();

  return (
    <div className="result">
      {isLoading ? (
        <div className="h-full w-full flex flex-col items-center justify-center">
          <QtmProgressCircle classes="" track size="xlarge" />
        </div>
      ) : data ? (
        <Outlet />
      ) : null}
    </div>
  );
};

export default Result;
