import { QtmIcon, QtmTypography } from '@qtm/react';
import clsx from 'clsx';
import { formatTimePerfDuration } from 'utils/date-utils';

type Props = {
  revisitTime: number;
  inline?: boolean;
  className?: string;
};

const ConstellationResultRevisitTime = ({ revisitTime, inline = false, className }: Props) => {
  const duration = formatTimePerfDuration(revisitTime);

  if (inline) {
    return (
      <div className="flex justify-between items-center">
        <div className="flex">
          <QtmIcon icon="timer" size="large" />
          <QtmTypography className="text-scale-6 text-white-100 whitespace-nowrap pl-m">
            Revisit time:
          </QtmTypography>
        </div>
        <QtmTypography className="text-scale-5 text-white-100">{duration}</QtmTypography>
      </div>
    );
  }

  return (
    <div className={clsx('flex items-center', className)}>
      <QtmIcon icon="timer" size="xlarge" />
      <div className="ml-m">
        <QtmTypography classes="text-scale-6 text-white-100 whitespace-nowrap">
          Revisit time:
        </QtmTypography>
        <QtmTypography classes="text-scale-5 text-white-100">{duration}</QtmTypography>
      </div>
    </div>
  );
};

export default ConstellationResultRevisitTime;
