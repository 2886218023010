import { CSSProperties } from 'react';

interface Props {
  colors: string[];
  className: string;
  vertical?: boolean;
}

const ColorGradient = ({ colors, className, vertical = false }: Props) => {
  const deg = vertical ? '0' : '90';

  const style: CSSProperties = {
    background: `linear-gradient(${deg}deg, ${colors.join(',')})`,
  };

  return <div className={className} style={style}></div>;
};
export default ColorGradient;
