import { QtmButton, QtmIcon } from '@qtm/react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGetResult from 'modules/results/result-hook';

import { setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationPreviousAction = () => {
  const dispatch = useAppDispatch();
  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { file } = useGetResult();

  const onBackwardClick = () => {
    if (selection && file) {
      const poi = file.pointOfInterest.find(poi => poi.id === selection.id);
      if (poi) {
        let previousOpp = null as null | Dayjs;

        poi.satellites.forEach(sat => {
          sat.values.forEach(satValue => {
            const epoch = dayjs(satValue.epoch);
            if (epoch.isBefore(currentDate)) {
              if (previousOpp === null || epoch.isAfter(previousOpp)) {
                previousOpp = epoch;
              }
            }
          });
        });
        if (previousOpp) {
          dispatch(setCurrentDate(previousOpp.valueOf()));
        }
      }
    }
  };

  const isDisabled = animationState === 'disabled' || !selection;
  const title = !selection ? 'Previous Opportunity' : '';

  return (
    <QtmButton
      size="large"
      color="primary"
      onClick={onBackwardClick}
      disabled={isDisabled}
      title={title}
    >
      <QtmIcon icon="fast_rewind" />
    </QtmButton>
  );
};

export default AnimationPreviousAction;
