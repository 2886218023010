import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { ManipulateType } from 'dayjs';
import { FileResult, PointOfInterest } from 'shared/model/resultFile.model';
import { AppThunk } from 'store';

export type animationState = 'disabled' | 'loading' | 'ready' | 'playing' | 'pause';

export interface AnimationSpeed {
  value: number;
  unit: ManipulateType;
}

const initialState = {
  loading: false,
  animationState: 'disabled' as animationState,
  start: null as number | null,
  end: null as number | null,
  currentDate: null as number | null,
  speed: {
    value: 10,
    unit: 'seconds',
  } as AnimationSpeed,
  selection: null as PointOfInterest | null,
  error: null as any,
};

export type ResultState = Readonly<typeof initialState>;

export const slice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setStartDate(state, action: PayloadAction<number>) {
      state.start = action.payload;
    },
    setEndDate(state, action: PayloadAction<number>) {
      state.end = action.payload;
    },
    setAnimationState(state, action: PayloadAction<animationState>) {
      state.animationState = action.payload;
    },
    setAnimationSpeed(state, action: PayloadAction<AnimationSpeed>) {
      state.speed = action.payload;
    },
    setCurrentDate(state, action: PayloadAction<number | null>) {
      state.currentDate = action.payload;
    },
    setSelection(state, action: PayloadAction<PointOfInterest | null>) {
      state.selection = action.payload;
    },
    reset: state => {
      state.loading = false;
      state.animationState = 'disabled';
      state.error = null;
      state.start = null;
      state.end = null;
      state.currentDate = null;
      state.selection = null;
    },
  },
});

const { setStartDate, setEndDate } = slice.actions;

export const { setAnimationState, setCurrentDate, setAnimationSpeed, setSelection } = slice.actions;

export const toNextDate = (): AppThunk => async (dispatch, getState) => {
  const currentDate = getState().animation.currentDate;
  const speed = getState().animation.speed;
  if (currentDate) {
    const nextDate = dayjs(currentDate).add(speed.value, speed.unit);
    dispatch(setCurrentDate(nextDate.valueOf()));
  }
};

export const initAnimation =
  (result: FileResult): AppThunk =>
  async dispatch => {
    const start = dayjs(result.startDate);
    const end = start.add(result.duration, 'days');
    dispatch(setStartDate(start.valueOf()));
    dispatch(setEndDate(end.valueOf()));
    dispatch(setCurrentDate(start.valueOf()));
  };

export default slice.reducer;
