import { QtmButton, QtmTypography } from '@qtm/react';
import DOMPurify from 'dompurify';
import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import ConstellationResultCost from 'modules/sat/constellations/ConstellationResultCost';
import ConstellationResultPerformance from 'modules/sat/constellations/ConstellationResultPerformance';
import ConstellationResultRevisitTime from 'modules/sat/constellations/ConstellationResultRevisitTime';
import { useNavigate } from 'react-router-dom';
import { ConstellationResult } from 'shared/model/ConstellationResult.model';

type Props = {
  item: ConstellationResult;
};

const ConstellationResultItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const { isSm } = useTailwindBreakpoints();

  const onSelect = () => {
    navigate(`/results/${item.id}/main`);
  };

  const configuration = item.constellationConfiguration;

  const name = configuration?.name ?? 'No name available';
  const description = configuration?.description
    ? DOMPurify.sanitize(configuration.description.replaceAll('\n', '<br/>'))
    : 'No description available';

  const cost = configuration?.cost ?? 0;

  const title = isSm ? name.split('+').join('+<br/>') : name;

  return (
    <div className="constellation-item">
      <div className="flex justify-between items-start mb-m">
        <div>
          <QtmTypography
            classes="text-scale-5 text-white-100"
            dangerouslySetInnerHTML={{ __html: title }}
          >
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
          </QtmTypography>
          <div
            className="text-white-60 hidden md:block"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
        <div className="hidden md:block">
          <ConstellationResultRevisitTime revisitTime={item.revisitTime} />
        </div>
      </div>
      <div className="hidden md:flex justify-between items-start ">
        <div className="flex justify-between grow-1">
          <div className="pr-xl border-e-thin">
            <ConstellationResultPerformance revisitTime={item.revisitTime} />
          </div>
          <div className="ml-xl">
            <ConstellationResultCost value={cost} />
          </div>
        </div>
        <QtmButton
          label="See performances"
          rightIcon="arrow_forward_ios"
          color="primary"
          variant="outline"
          onClick={onSelect}
        />
      </div>
      <div className="xs:block md:hidden">
        <ConstellationResultRevisitTime revisitTime={item.revisitTime} inline />
        <ConstellationResultPerformance revisitTime={item.revisitTime} inline />
        <ConstellationResultCost value={cost} inline />
        <div className="flex justify-end mt-m">
          <QtmButton
            label="See performances"
            rightIcon="arrow_forward_ios"
            color="primary"
            variant="outline"
            onClick={onSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default ConstellationResultItem;
