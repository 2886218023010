import Point from '@arcgis/core/geometry/Point';
import dayjs from 'dayjs';
import { AcquisitionOppMetadata } from 'modules/results/opportunities/AcquisitionOppMetadata';
import useGetResult from 'modules/results/result-hook';
import { useEffect, useState } from 'react';

import {
  EAxisAlignment,
  ENumericFormat,
  EllipsePointMarker,
  HitTestInfo,
  NumberRange,
  NumericAxis,
  SciChartSurface,
  SweepAnimation,
  TextLabelProvider,
  XyDataSeries,
  XyScatterRenderableSeries,
} from 'scichart';
import { PointOfInterest } from 'shared/model/resultFile.model';
import { getChartPopup } from 'shared/scischart/ChartPopup';
import { DetectOnOverSeriesModifier } from 'shared/scischart/detectOnOverSeriesModifier';
import { addChartNotifiers } from 'shared/scischart/scichart.def';
import { rgbArrayToHex } from 'utils/color-utils';
import { getLocaleDateFromPosition } from 'utils/map.utils';
import { getSatColor } from 'utils/result-utils';

const id = 'acquisition-opp-chart';

type Props = {
  poi: PointOfInterest | undefined;
};

const ResultOpportunitiesSciChart = ({ poi }: Props) => {
  const [chartSurface, setChartSurface] = useState<SciChartSurface>();
  const [popup, setPopup] = useState<JSX.Element>();

  const { file } = useGetResult();

  useEffect(() => {
    if (file && poi) {
      const initChart = async () => {
        setPopup(undefined);
        const startDate = dayjs(file.startDate);

        const simulationDays = file.duration;

        const { sciChartSurface, wasmContext } = await SciChartSurface.create(id);
        const opportunitiesDaysCount = new Array(simulationDays + 2).fill(0);

        if (poi.satellites) {
          const firstPoint = new Point({
            longitude: poi.longitude,
            latitude: poi.latitude,
          });

          const localStartDate = await getLocaleDateFromPosition(startDate, firstPoint);

          const orderedByDate = poi.satellites.reduce((agg: string[], current) => {
            current.values.forEach(value => {
              agg.push(value.epoch);
            });
            return agg;
          }, []);
          orderedByDate.sort();
          //console.table(orderedByDate);

          const debugDates: any[] = [];
          for (let i = 0; i < poi.satellites.length; i++) {
            const sat = poi.satellites[i];
            const xValues: number[] = [];
            const yValues: number[] = [];
            const metadata: AcquisitionOppMetadata[] = [];

            const id = sat.satId;
            const color = getSatColor(file, sat.name);

            //const color = sat.name.includes('HRR') ? HRRColor : HROColor;

            for (let j = 0; j < sat.values.length; j++) {
              const satValue = sat.values[j];

              const epoch = dayjs(satValue.epoch);
              const localDate = await getLocaleDateFromPosition(epoch, firstPoint);

              const hours = localDate.get('hours');
              const minutes = localDate.get('minutes');
              const minuteDecimal = minutes / 60;
              const y = hours + minuteDecimal;

              const x = localDate.diff(localStartDate, 'day') + 1;

              xValues.push(x);
              yValues.push(y);
              opportunitiesDaysCount[x]++;

              const previousItem = j === 0 ? undefined : sat.values[j - 1];
              let previousOpportunities = undefined;
              if (previousItem) {
                const previousDate = dayjs(previousItem.epoch);
                previousOpportunities = await getLocaleDateFromPosition(previousDate, firstPoint);
              }

              const previousSatIndex = orderedByDate.findIndex(date => date === satValue.epoch);
              let previousSatDate = undefined;
              if (previousSatIndex > 0) {
                const previousDate = dayjs(orderedByDate[previousSatIndex - 1]);
                previousSatDate = await getLocaleDateFromPosition(previousDate, firstPoint);
              }

              const satMetadata = {
                ...sat,
                name: sat.name,
              };

              const oppData = new AcquisitionOppMetadata(
                startDate,
                satMetadata,
                undefined,
                localDate,
                previousOpportunities,
                previousSatDate,
                rgbArrayToHex(color),
              );

              debugDates.push([
                sat.satId,
                x,
                y,
                satValue.epoch,
                hours,
                minutes,
                previousOpportunities?.toISOString(),
                previousSatDate?.toISOString(),
              ]);

              metadata.push(oppData);
            }
            /*debugDates.sort((d1, d2) => {
                return (d1[3] as string).localeCompare(d2[3]);
              });
              console.table(debugDates);*/
            const scatterSeries = new XyScatterRenderableSeries(wasmContext, {
              dataSeries: new XyDataSeries(wasmContext, {
                xValues,
                yValues,
                metadata,
              }),
              pointMarker: new EllipsePointMarker(wasmContext, {
                width: 8,
                height: 8,
                strokeThickness: 0,
                fill: rgbArrayToHex(color),
                stroke: rgbArrayToHex(color),
              }),
              opacity: 1,
              animation: new SweepAnimation({
                duration: 300,
                fadeEffect: true,
              }),
              //paletteProvider: new SatModelPaletteProvider(),
            });

            sciChartSurface.renderableSeries.add(scatterSeries);
          }
        }

        const labels = [...opportunitiesDaysCount];

        sciChartSurface.xAxes.add(
          new NumericAxis(wasmContext, {
            axisTitle: 'Simulation Days',
            axisTitleStyle: {
              fontSize: 16,
              fontWeight: 'bold',
            },
            visibleRange: new NumberRange(0.5, simulationDays + 0.5),
            visibleRangeLimit: new NumberRange(0.5, simulationDays + 0.5),
            drawMajorBands: false,
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            autoTicks: false,
            minorDelta: 1,
            majorDelta: 1,
            labelFormat: ENumericFormat.NoFormat,
            zoomExtentsToInitialRange: true,
          }),
          new NumericAxis(wasmContext, {
            axisAlignment: EAxisAlignment.Top,
            axisTitle: 'Total number of oppportunities per day',
            axisTitleStyle: {
              fontSize: 16,
              fontWeight: 'bold',
            },
            visibleRange: new NumberRange(0.5, simulationDays + 0.5),
            visibleRangeLimit: new NumberRange(0.5, simulationDays + 0.5),
            drawMajorBands: false,
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            autoTicks: false,
            minorDelta: 1,
            majorDelta: 1,
            labelFormat: ENumericFormat.NoFormat,
            labelStyle: {
              fontStyle: 'normal',
            },
            zoomExtentsToInitialRange: true,
            labelProvider: new TextLabelProvider({
              labels: labels,
            }),
          }),
        );

        sciChartSurface.yAxes.add(
          new NumericAxis(wasmContext, {
            visibleRange: new NumberRange(0, 24),
            visibleRangeLimit: new NumberRange(0, 24),
            axisAlignment: EAxisAlignment.Left,
            labelFormat: ENumericFormat.NoFormat,
            drawMajorBands: false,
            drawMajorGridLines: false,
            drawMinorGridLines: false,
            autoTicks: false,
            minorDelta: 1,
            majorDelta: 2,
            zoomExtentsToInitialRange: true,
          }),
        );

        sciChartSurface.chartModifiers.add(
          new DetectOnOverSeriesModifier({
            onHover: (hitTestInfo: HitTestInfo) => {
              if (hitTestInfo.isHit) {
                const newPopup = getChartPopup({
                  metaData: hitTestInfo.metadata as AcquisitionOppMetadata,
                  xCoord: hitTestInfo.xCoord,
                  yCoord: hitTestInfo.yCoord,
                });
                setPopup(newPopup);
              }
            },
            onOut() {
              setPopup(undefined);
            },
          }),
        );

        addChartNotifiers(sciChartSurface);

        //sciChartSurface.applyTheme(sciChartTheme);

        setChartSurface(sciChartSurface);
      };
      if (poi) {
        initChart();
      }

      return () => {
        chartSurface?.delete();
      };
    }
  }, [file, poi]);

  return (
    <div className="result-chart">
      <div id={id}></div>
      {popup}
    </div>
  );
};

export default ResultOpportunitiesSciChart;
