import Graphic from '@arcgis/core/Graphic';
import LineSymbol3D from '@arcgis/core/symbols/LineSymbol3D';
import LineSymbol3DLayer from '@arcgis/core/symbols/LineSymbol3DLayer';
import ObjectSymbol3DLayer from '@arcgis/core/symbols/ObjectSymbol3DLayer';
import PointSymbol3D from '@arcgis/core/symbols/PointSymbol3D';
import LineStylePattern3D from '@arcgis/core/symbols/patterns/LineStylePattern3D';
import { SatelliteModel } from 'shared/model/resultFile.model';
import { HRO3DModel, HRR3DModel } from 'shared/model/sateliteModel.constants';
import { deg2rad } from 'utils/math.utils';
import { getSatColorFromModel } from 'utils/result-utils';

const simple = false;

const earthCurvature = 500000; // prise en compte de la courbure de la terre

const getSymbolLayer = (satModel: SatelliteModel | undefined, azimuth: number) => {
  const color = getSatColorFromModel(satModel);

  if (simple || !satModel) {
    return new ObjectSymbol3DLayer({
      height: 130000,
      resource: {
        primitive: 'sphere',
      },
      material: {
        color,
      },
      castShadows: false,
    });
  }
  return new ObjectSymbol3DLayer({
    height: 150000,
    resource: {
      href: satModel.isRadar ? HRR3DModel : HRO3DModel,
    },
    heading: azimuth,
    castShadows: false,
  });
};

export const getSatelliteSymbol = (
  satModel: SatelliteModel | undefined,
  height: number,
  azimuth: number,
) => {
  const color = getSatColorFromModel(satModel);

  const firstAngle = satModel?.firstAngle ?? 0;

  const symbol = new PointSymbol3D({
    symbolLayers: [getSymbolLayer(satModel, azimuth)],
  });

  const heightInMeter = height * 1000 + earthCurvature;
  if (satModel?.isOptical && firstAngle > 0) {
    const angle = deg2rad(firstAngle + 2);
    const width = Math.tan(angle) * heightInMeter * 2;
    const depth = width;
    const opticalColor = [...color, 0.5];

    const opticalSymbol = new ObjectSymbol3DLayer({
      anchor: 'top',
      height: heightInMeter,
      depth,
      width,
      resource: {
        primitive: 'cone',
      },
      material: {
        color: opticalColor,
      },
      castShadows: false,
    });
    symbol.symbolLayers.push(opticalSymbol);
  }

  return symbol;
};

export const getSatelliteTailSymbol = (satModel: SatelliteModel | undefined) => {
  const color = getSatColorFromModel(satModel);

  return new LineSymbol3D({
    symbolLayers: [
      new LineSymbol3DLayer({
        size: 2,
        material: {
          color,
        },
        pattern: new LineStylePattern3D({
          style: 'solid',
        }),
      }),
    ],
  });
};

export const updateModel3DOrientationWithAzimut = (g: Graphic, az: number) => {
  if (simple) {
    return;
  }

  const symbol = g.symbol as PointSymbol3D;
  const newSymbol = symbol.clone();

  const symbolLayers = newSymbol.symbolLayers;
  symbolLayers.forEach((item: any) => {
    if (item instanceof ObjectSymbol3DLayer) {
      item.heading = az;
    }
  });
  g.symbol = newSymbol;
};
