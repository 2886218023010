import { QtmButton, QtmIcon } from '@qtm/react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGetResult from 'modules/results/result-hook';
import { setCurrentDate } from 'shared/reducers/animationSlice';

const AnimationNextAction = () => {
  const dispatch = useAppDispatch();

  const animationState = useAppSelector(({ animation }) => animation.animationState);
  const currentDate = useAppSelector(({ animation }) => animation.currentDate);
  const selection = useAppSelector(({ animation }) => animation.selection);

  const { file } = useGetResult();

  const onClick = () => {
    if (selection && file) {
      const poi = file.pointOfInterest.find(poi => poi.id === selection.id);
      if (poi) {
        let nextOpp = null as null | Dayjs;

        poi.satellites.forEach(sat => {
          sat.values.forEach(satValue => {
            const epoch = dayjs(satValue.epoch);
            if (epoch.isAfter(currentDate)) {
              if (nextOpp === null || epoch.isBefore(nextOpp)) {
                nextOpp = epoch;
              }
            }
          });
        });
        if (nextOpp) {
          dispatch(setCurrentDate(nextOpp.valueOf()));
        }
      }
    }
  };

  const isDisabled = animationState === 'disabled' || !selection;

  const title = !selection ? 'Nexte Opportunity' : '';

  return (
    <QtmButton size="large" onClick={onClick} disabled={isDisabled} title={title}>
      <QtmIcon icon="fast_forward" />
    </QtmButton>
  );
};

export default AnimationNextAction;
