import Point from '@arcgis/core/geometry/Point';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapView from '@arcgis/core/views/MapView';
import { Dayjs } from 'dayjs';
import Storage from 'utils/storage-utils';

const worldTimeZoneLayer = new FeatureLayer({
  url: 'https://services.arcgis.com/xdbDcZrQnv51VAwh/arcgis/rest/services/World_Time_Zones/FeatureServer',
});

interface IPointTimeZone {
  x: number;
  y: number;
  zone: number;
}

const pointZones: IPointTimeZone[] = Storage.local.get('skyo-pointZones') ?? [];

export const getLocaleDateFromPosition = async (date: Dayjs, point: Point) => {
  try {
    const timeZone = pointZones.find(z => z.x === point.x && z.y === point.y);
    if (!timeZone) {
      const result = await worldTimeZoneLayer.queryFeatures({
        spatialRelationship: 'intersects',
        geometry: point,
        outFields: ['ZONE'],
        returnGeometry: false,
      });

      const feature = result.features[0];
      const zone = feature.getAttribute('ZONE');

      pointZones.push({
        x: point.x,
        y: point.y,
        zone,
      });
      Storage.local.set('skyo-pointZones', pointZones);
      return date.utcOffset(zone);
    } else {
      return date.utcOffset(timeZone.zone);
    }
  } catch (e) {
    console.error(e);
    return date;
  }
};

export const disableZooming = (view: MapView) => {
  // Removes the zoom action on the popup
  //view.popup.actions = [];

  // stops propagation of default behavior when an event fires
  function stopEvtPropagation(event: any) {
    event.stopPropagation();
  }

  // exlude the zoom widget from the default UI
  view.ui.components = ['attribution'];

  // disable mouse wheel scroll zooming on the view
  view.on('mouse-wheel', stopEvtPropagation);

  // disable zooming via double-click on the view
  view.on('double-click', stopEvtPropagation);

  // disable zooming out via double-click + Control on the view
  view.on('double-click', ['Control'], stopEvtPropagation);

  // disables pinch-zoom and panning on the view
  view.on('drag', stopEvtPropagation);

  // disable the view's zoom box to prevent the Shift + drag
  // and Shift + Control + drag zoom gestures.
  view.on('drag', ['Shift'], stopEvtPropagation);
  view.on('drag', ['Shift', 'Control'], stopEvtPropagation);

  // prevents zooming with the + and - keys
  view.on('key-down', event => {
    const prohibitedKeys = [
      '+',
      '-',
      'Shift',
      '_',
      '=',
      'ArrowUp',
      'ArrowDown',
      'ArrowRight',
      'ArrowLeft',
    ];
    const keyPressed = event.key;
    if (prohibitedKeys.indexOf(keyPressed) !== -1) {
      event.stopPropagation();
    }
  });

  return view;
};
