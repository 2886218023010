import { QtmTypography } from '@qtm/react';
import { Outlet } from 'react-router-dom';

import useTailwindBreakpoints from 'hooks/useTailwindBreakpoints';
import './area.css';

const ChooseArea = () => {
  const { isSm } = useTailwindBreakpoints();
  const titleSize = isSm ? 'title-3' : 'title-2';

  return (
    <>
      <QtmTypography component={titleSize} classes="text-white-100">
        <span className="hidden md:inline">Select your Area of Interest</span>
        <span className="xs:inline md:hidden">Area of Interest</span>
      </QtmTypography>
      <Outlet />
    </>
  );
};

export default ChooseArea;
